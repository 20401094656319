import {Injectable, NgZone} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {from, Subject, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';

// Workaround for rollup library behaviour, as pointed out on issue #1267 (https://github.com/rollup/rollup/issues/1267).
import * as Keycloak_ from 'keycloak-ionic';
import {ExcludedUrl, ExcludedUrlRegex, KeycloakOptions, } from './interfaces/keycloak-options';
import {KeycloakEvent, KeycloakEventType} from './interfaces/keycloak-event';
import {ModalController, Platform} from "@ionic/angular";
import {EnvService} from "../environment/env.service";
import {NavigationService} from "../navigation/navigation.service";
import {
  AccountForm, AccountType,
  AddressForm,
  AuthProvider,
  CustomerProfileForm, CustomerProfilService,
  KcTokens, OAuthService, RegisterAccountForm,
  SecurityService,
  VendorProfileForm,
  VendorProfilService
} from "@cdq/api";
import {ActivatedRoute, Router} from "@angular/router";
import {NotificationsService} from "../local-notifications/notifications.service";
import {AlertService} from "../alert/alert.service";
import {SharedService} from "../shared/shared.service";
import {KeycloakSocialLoginService} from './keycloak-social-login.service';
import {Preferences} from '@capacitor/preferences';
import * as AppConstants from '../../app.constants';

export const Keycloak = Keycloak_;
declare const gtag: Function;


@Injectable({
  providedIn: 'root'
})
export class KeycloakIonicService {


  alreadyInit: boolean = false;

  constructor(
    public platform: Platform,
    private envService: EnvService,
    private navigationService: NavigationService,
    private securityService: SecurityService,
    private router: Router,
    private notificationService: NotificationsService,
    private vendorProfileService: VendorProfilService,
    private ngZone: NgZone,
    private alertService: AlertService,
    private sharedEvent: SharedService,
    private route: ActivatedRoute,
    private keycloakSocialLoginService: KeycloakSocialLoginService,
    private oauthService: OAuthService,
    private clientService: CustomerProfilService,
    private http: HttpClient,
    private modalCtrl: ModalController,
    // private ga: GaService
  ) {

  }

  private appBaseUri: string = 'cdqapppro';

  /**
   * Keycloak-js instance.
   */
  private _instance: Keycloak.KeycloakInstance;
  /**
   * User profile as KeycloakProfile interface.
   */
  private _userProfile: Keycloak.KeycloakProfile;
  /**
   * Flag to indicate if the bearer will not be added to the authorization header.
   */
  private _enableBearerInterceptor: boolean = false;
  /**
   * When the implicit flow is choosen there must exist a silentRefresh, as there is
   * no refresh token.
   */
  private _silentRefresh: boolean = false;
  /**
   * Indicates that the user profile should be loaded at the keycloak initialization,
   * just after the login.
   */
  private _loadUserProfileAtStartUp: boolean = false;
  /**
   * The bearer prefix that will be appended to the Authorization Header.
   */
  private _bearerPrefix: string = '';
  /**
   * Value that will be used as the Authorization Http Header name.
   */
  private _authorizationHeaderName: string = '';
  /**
   * The excluded urls patterns that must skip the KeycloakBearerInterceptor.
   */
  private _excludedUrls: ExcludedUrlRegex[] = [];
  /**
   * Observer for the keycloak events
   */
  private _keycloakEvents$: Subject<KeycloakEvent> = new Subject<KeycloakEvent>();

  /**
   * Binds the keycloak-js events to the keycloakEvents Subject
   * which is a good way to monitor for changes, if needed.
   *
   * The keycloakEvents returns the keycloak-js event type and any
   * argument if the source function provides any.
   */
  private bindsKeycloakEvents(): void {
    this._instance.onAuthError = (errorData) => {
      this._keycloakEvents$.next({
        args: errorData,
        type: KeycloakEventType.OnAuthError,
      });
    };

    this._instance.onAuthLogout = () => {
      this._keycloakEvents$.next({type: KeycloakEventType.OnAuthLogout});
    };

    this._instance.onAuthRefreshSuccess = () => {
      this._keycloakEvents$.next({
        type: KeycloakEventType.OnAuthRefreshSuccess,
      });
    };

    this._instance.onAuthRefreshError = () => {
      this._keycloakEvents$.next({
        type: KeycloakEventType.OnAuthRefreshError,
      });
    };

    this._instance.onAuthSuccess = (args?: any) => {
      this._keycloakEvents$.next({type: KeycloakEventType.OnAuthSuccess, args: args});
    };

    this._instance.onTokenExpired = () => {
      this._keycloakEvents$.next({
        type: KeycloakEventType.OnTokenExpired,
      });
    };

    this._instance.onReady = (authenticated) => {
      this._keycloakEvents$.next({
        args: authenticated,
        type: KeycloakEventType.OnReady,
      });
    };
  }

  /**
   * Loads all bearerExcludedUrl content in a uniform type: ExcludedUrl,
   * so it becomes easier to handle.
   *
   * @param bearerExcludedUrls array of strings or ExcludedUrl that includes
   * the url and HttpMethod.
   */
  private loadExcludedUrls(
    bearerExcludedUrls: (string | ExcludedUrl)[]
  ): ExcludedUrlRegex[] {
    const excludedUrls: ExcludedUrlRegex[] = [];
    for (const item of bearerExcludedUrls) {
      let excludedUrl: ExcludedUrlRegex;
      if (typeof item === 'string') {
        excludedUrl = {urlPattern: new RegExp(item, 'i'), httpMethods: []};
      } else {
        excludedUrl = {
          urlPattern: new RegExp(item.url, 'i'),
          httpMethods: item.httpMethods,
        };
      }
      excludedUrls.push(excludedUrl);
    }
    return excludedUrls;
  }

  /**
   * Handles the class values initialization.
   *
   * @param options
   */
  private initServiceValues({
    enableBearerInterceptor = true,
    loadUserProfileAtStartUp = false,
    bearerExcludedUrls = [],
    authorizationHeaderName = 'Authorization',
    bearerPrefix = 'Bearer',
    initOptions,
  }: KeycloakOptions): void {
    this._enableBearerInterceptor = enableBearerInterceptor;
    this._loadUserProfileAtStartUp = loadUserProfileAtStartUp;
    this._authorizationHeaderName = authorizationHeaderName;
    this._bearerPrefix = bearerPrefix.trim().concat(' ');
    this._excludedUrls = this.loadExcludedUrls(bearerExcludedUrls);
    this._silentRefresh = initOptions ? initOptions.flow === 'implicit' : false;
  }

  /**
   * Keycloak initialization. It should be called to initialize the adapter.
   * Options is a object with 2 main parameters: config and initOptions. The first one
   * will be used to create the Keycloak instance. The second one are options to initialize the
   * keycloak instance.
   *
   * @param options
   * Config: may be a string representing the keycloak URI or an object with the
   * following content:
   * - url: Keycloak json URL
   * - realm: realm name
   * - clientId: client id
   *
   * initOptions:
   * Options to initialize the Keycloak adapter, matches the options as provided by Keycloak itself.
   *
   * enableBearerInterceptor:
   * Flag to indicate if the bearer will added to the authorization header.
   *
   * loadUserProfileInStartUp:
   * Indicates that the user profile should be loaded at the keycloak initialization,
   * just after the login.
   *
   * bearerExcludedUrls:
   * String Array to exclude the urls that should not have the Authorization Header automatically
   * added.
   *
   * authorizationHeaderName:
   * This value will be used as the Authorization Http Header name.
   *
   * bearerPrefix:
   * This value will be included in the Authorization Http Header param.
   *
   * @returns
   * A Promise with a boolean indicating if the initialization was successful.
   */
  public async init(options: KeycloakOptions = {}, login?: boolean) {
    console.log('[keycloak service] options init', options);
    this.initServiceValues(options);
    const {config, initOptions} = options;

    this._instance = Keycloak(config);
    this.bindsKeycloakEvents();

    this.setLoginSubscriptions('login')

    const authenticated = await this._instance.init(initOptions);

    if (authenticated && this._loadUserProfileAtStartUp) {
      await this.loadUserProfile();
    }
    // if(login) {
    //   this.login({
    //     prompt: 'none',

    //   });
    // }

    return authenticated;
  }

  parseJWTToken(token: string) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }


  public isMobile() {
    return (this.platform.platforms().includes("mobile") && !this.platform.platforms().includes("mobileweb")) || ((this.platform.platforms().includes("android") ||
      this.platform.platforms().includes("ios")) && !this.platform.platforms().includes("mobileweb"));
  }


  public setLoginSubscriptions(source: string): void {
    console.log('[keycloak ionic service] subscribing on ' + source);
    const sub = this._keycloakEvents$.subscribe((val) => {
      console.log('[keycloak event]: ', val)
      if (val.type == KeycloakEventType.OnAuthSuccess) {
        try {

          let kcTokens = {
            accessToken: this._instance.token,
            refreshToken: this._instance.refreshToken,
            idToken: this._instance.idToken
          }

          Preferences.set({key: 'kcTokens', value: JSON.stringify(kcTokens)}).then(
            () => console.log('[keycloak success] success: keycloak token Stored!'),
            (error) =>
              console.error('[keycloak success] Error storing item', error)
          );

          console.log('[keycloak success]', this._instance.token, this._instance.refreshToken);
          if (this._instance.token) {
            this.sharedEvent.sendToken(this._instance.token);
            console.log('[keycloak success] token shared');
          }

          this._instance.loadUserProfile().then((val: any) => {
            console.log('[keycloak success] user profile', val)
            this.securityService.apiV1SecurityLoadGet().forEach(r => {
              console.log('[keycloak ionic service] security load', r);
              if (!r.result) {
                console.log('user is logged in keycloak, but no data. kc profil', val)
                let provider = undefined;
                if (val.attributes && val.attributes.idp_source && val.attributes.idp_source.length > 0) {
                  provider = val.attributes.idp_source[0]
                }
                console.log('found provider:', provider)
                if (provider) {
                  this.keycloakSocialLoginService.socialProviderInfo(provider, this._instance).then((res: any) => {

                    let generalVendorAccount: VendorProfileForm = new class implements VendorProfileForm {
                      account: AccountForm = new class implements AccountForm {
                        firstname: string = '';
                        lastname: string = '';
                        phoneNumber: string;
                      }
                      phoneNumber: string;
                      fixPhoneNumber: string;
                      defaultAddress: AddressForm;
                    }
                    let newAccount: RegisterAccountForm = new class implements RegisterAccountForm {
                      accountType: AccountType = AccountType.Vendor;
                      email: string = '';
                      firstname: string = '';
                      lastname: string = '';
                      phoneNumber: string = '';
                      authProvider: AuthProvider = AuthProvider.App;
                    }
                    if (provider == 'apple') {
                      newAccount.authProvider = AuthProvider.Apple;
                      newAccount.email = val.email;
                      newAccount.lastname = val.lastName;
                      newAccount.firstname = val.firstName;

                    } else if (provider == 'facebook') {
                      newAccount.authProvider = AuthProvider.Facebook;
                      newAccount.email = res.email;
                      newAccount.firstname = res.first_name;
                      newAccount.lastname = res.last_name;


                    } else if (provider = 'google') {
                      newAccount.authProvider = AuthProvider.Google;
                      newAccount.email = res.emailAddresses[0].value;
                      newAccount.firstname = res.names[0].givenName;
                      newAccount.lastname = res.names[0].familyName;
                    }

                    this.oauthService.connexion(newAccount).subscribe((res) => {
                      console.log('[register social] account created', res)
                      if (res.result) {
                        if (newAccount.authProvider == AuthProvider.Apple) {
                          generalVendorAccount.account.firstname = newAccount.firstname;
                          generalVendorAccount.account.lastname = newAccount.lastname;
                          generalVendorAccount.account.phoneNumber = newAccount.phoneNumber;
                          this.vendorProfileService.profileSave(generalVendorAccount).forEach(r => {
                            console.log('[keycloak ionic service] apple client profile saved');
                            this.ngZone.run(() => {
                              if (!this.isMobile()) {
                                this.router.navigateByUrl(AppConstants.appDefaultConnectedWebRoute, {
                                  replaceUrl: true,
                                });
                              } else if (r.result.moderationStatus != 'ACCEPTED') {
                                //mobile only
                                console.log('[keycloak] logged in so navigating to vendor profile page for complete ', AppConstants.appNotModeratedMobileRoute);
                                this.router.navigateByUrl(AppConstants.appNotModeratedMobileRoute);
                              } else {
                                //mobile only
                                this.router.navigateByUrl(AppConstants.appDefaultConnectedMobileRoute);
                              }
                            })
                          }).catch(e => {
                            console.log('error on profile save', e);
                            this.alertService.temp('ERROR API', 2000);
                          });
                        } else if (newAccount.authProvider == AuthProvider.Google) {
                          //redirection is inside call
                          this.keycloakSocialLoginService.saveGoogleInfo(res, generalVendorAccount, newAccount);
                        } else if (newAccount.authProvider == AuthProvider.Facebook) {
                          //redirection is inside call
                          this.keycloakSocialLoginService.saveFacebookInfo(res, generalVendorAccount, newAccount);
                        } else {
                          console.log('[keycloak ionic service] social login unkown provider');
                        }
                      } else {
                        console.log('[keycloak ionic service] social login failed after trying to save account');
                      }
                    });
                  })
                } else {
                  //
                  console.log('[keycloak ionic service] social login failed for unknow reason. should throw an error here');
                }
              } else {
                console.log('user found, can redirect on');
                this.notificationService.updateDeviceTokenWhenLoggedId(r);

                this.ngZone.run(() => {
                  this.navigationService.refresh(true, r.result.userType);
                  if (!this.notificationService.tapOpened) {
                    if (this.router.url.includes('client-offer-detail')) {
                      this.sharedEvent.sendRefreshOfferViewEvent();
                    }

                    if (r.result.userType == 'VENDOR') {
                      console.log('[PROUSER] logged in so navigating to pro home page');
                      console.log(' router url on open ', this.router.url)
                      console.log(' isMobile: ', this.isMobile());
                      this.vendorProfileService.vendorProfileLoad().subscribe(profile => {
                        console.log('[keycloak ionic service] vendor profile', profile);
                        this.ngZone.run(() => {
                          this.navigationService.refresh(true, 'VENDOR');
                          this.alertService.dismissAllPending();
                          if(!this.isMobile()) {
                            this.router.navigateByUrl(AppConstants.appDefaultConnectedWebRoute, {
                              replaceUrl: true,
                            });
                          } else {
                            this.router.navigateByUrl(AppConstants.appDefaultConnectedMobileRoute, {
                              replaceUrl: true,
                            });
                          }
                          // if (profile.result.moderationStatus == 'TO_MODERATE') {
                          //   console.log('[keycloak ionic service] profile vendor is new', profile.result.moderationStatus);
                          //   this.router.navigateByUrl(AppConstants.appDefaultConnectedWebRouteComplete, {
                          //     replaceUrl: true,
                          //   });
                          // } else {
                          //   console.log('[keycloak ionic service] profile vendor is not new', profile.result.moderationStatus);
                          //   this.router.navigateByUrl(AppConstants.appDefaultConnectedMobileRoute, {
                          //     replaceUrl: true,
                          //   });
                          // }
                        })
                      });
                    } else if (r.result.userType == 'CLIENT') {
                      console.log('[PROUSER] logged in should disconnect');
                      console.log(' router url on open ', this.router.url)
                      console.log(' isMobile: ', this.isMobile());
                      console.log('[keycloak ionic service] existing client account, disconnecting, should switch app');
                      this.alertService.closeLoader();
                      this.alertService.ask('Compte existant', `Votre compte est déjà enregistré en tant que riverain, voulez-vous être redirigé vers l'autre application ?`, 'Oui, rediriger', () => {
                        if (!this.isMobile()) {
                          this.logout('/offline');
                        } else {
                          this.ngZone.run(() => {
                            this.navigationService.refresh(false, 'OFFLINE');
                            this.logout('/offline');
                          });
                        }
                      }, 'Non, rester', () => {
                        this.ngZone.run(() => {
                          this.navigationService.refresh(true, 'OFFLINE');
                        });
                        if (!this.isMobile()) {
                          this.logout('/offline');
                        } else {
                          this.ngZone.run(() => {
                            this.navigationService.refresh(false, 'OFFLINE');
                            this.logout('/offline');
                          });
                        }
                      }
                      );
                    } else {
                      //should throw error !!!
                      console.log('error, shouldnt be here');
                    }
                  } else {
                    // navigate to tapped url
                    let notificationUrl = this.notificationService.tapUrl;
                    this.notificationService.tapUrl = '';
                    this.notificationService.tapOpened = false;
                    this.router.navigateByUrl(notificationUrl);
                  }
                  console.log('[keycloak success]: could navigate. menu is refreshed. tapOpened: ' + this.notificationService.tapOpened);
                })


                if (this.isMobile()) {
                  console.log('[keycloak ionic service] platform is mobile, should use GA lib')
                  /*this.ga.setUserId(r.result.email).then((userId) => {
                      console.log('Set User ID : ', userId);
                  })*/
                } else {
                  gtag('config', this.envService.GA_TRACKING_ID, {
                    send_page_view: false,
                    user_id: r.result.email,
                    cdq_user: r.result.email
                  });
                }
              }
            });
          })
        } catch (e) {
          console.log('[keycloak success] error while seting token', e)
        }
      }

      if (val.type == KeycloakEventType.OnAuthError) {
        console.log('[keycloak auth error]')
        try {
          Preferences.remove({key: 'kcTokens'}).then(
            (va) => {
              console.log('[keycloak auth error]. removed tokens from storage');
            },
            (err) => {
              console.log(
                '[keycloak auth error]. failed to remove token from storage'
              );
            }
          );
        } catch (e) {
          console.log('[keycloak auth error] error while removing tokens');
        }

        this.ngZone.run(() => {
          this.navigationService.refresh(false, 'OFFLINE');
          console.log('[keycloak logout]. menu refreshed. could navigate');
          // this.alertService.dismissAllPending();
          if (!this.notificationService.tapOpened) {
            this.router.navigateByUrl('/offline/home-web');
          }
        })
      }

      if (val.type == KeycloakEventType.OnReady) {
        //when ready, route to app entry point
        this.ngZone.run(() => {
            this.router.navigateByUrl('/offline/home');
        })
      }

      if (val.type == KeycloakEventType.OnAuthLogout) {
        console.log('[keycloak logout]. removing token from Preferences')
        try {
          Preferences.remove({key: 'kcTokens'}).then(
            (va) => {
              console.log('[keycloak auth error]. removed tokens from storage');
            },
            (err) => {
              console.log(
                '[keycloak auth error]. failed to remove token from storage'
              );
            }
          );
        } catch (e) {
          console.log('error while removing tokens');
        }
        this.ngZone.run(() => {
          this.navigationService.refresh(false, 'OFFLINE');
          console.log('[keycloak logout]. menu refreshed. could navigate');
          this.router.navigateByUrl('/offline/home',);
        })
      }
    });
  }


  buildUrl(identityProvider: AuthProvider) {
    const type = identityProvider.toLowerCase();
    const keycloakUrl = `${this.envService.keycloak.url}`;
    const providerUrl = `realms/${this.envService.keycloak.realm}/protocol/openid-connect/auth?client_id=${this.envService.keycloak.clientId}`;
    let redirectBaseUri = "";
    if (this.isMobile()) {
      redirectBaseUri = this.envService.keycloak.redirectUri
    } else {
      redirectBaseUri = this.envService.keycloak.redirectUriBrowser
    }
    const redirectUri = `${redirectBaseUri}/register/social`;
    const params = `?kc_idp_hint=${type}&response_type=code&scope=openid&kc_idp_hint=${type}`;
    const url = `${keycloakUrl}/${providerUrl}&redirect_uri=${redirectUri}${params}`;

    return url;
  }


  public async loginSocial(identityProvider: AuthProvider) {
    if ([AuthProvider.Google, AuthProvider.Facebook, AuthProvider.Apple].includes(identityProvider)) {
      console.log('calling custom login for external provider')
      let regSocialUri = '';
      if (this.isMobile()) {
        regSocialUri = this.appBaseUri + '://register/social?kc_idp_hint=' + identityProvider.toLowerCase()
      } else {
        regSocialUri = this.envService.keycloak.redirectUriBrowser + '/register/social?kc_idp_hint=' + identityProvider.toLowerCase();
      }
      this.login({
        idpHint: identityProvider.toLowerCase(),
        redirectUri: regSocialUri
      })
    }
  }


  /**
   * Redirects to login form on (options is an optional object with redirectUri and/or
   * prompt fields).
   *
   * @param options
   * Object, where:
   *  - redirectUri: Specifies the uri to redirect to after login.
   *  - prompt:By default the login screen is displayed if the user is not logged-in to Keycloak.
   * To only authenticate to the application if the user is already logged-in and not display the
   * login page if the user is not logged-in, set this option to none. To always require
   * re-authentication and ignore SSO, set this option to login .
   *  - maxAge: Used just if user is already authenticated. Specifies maximum time since the
   * authentication of user happened. If user is already authenticated for longer time than
   * maxAge, the SSO is ignored and he will need to re-authenticate again.
   *  - loginHint: Used to pre-fill the username/email field on the login form.
   *  - action: If value is 'register' then user is redirected to registration page, otherwise to
   * login page.
   *  - locale: Specifies the desired locale for the UI.
   * @returns
   * A void Promise if the login is successful and after the user profile loading.
   */
  public async login(options: Keycloak.KeycloakLoginOptions = {}) {
    //this.alertService.showSpinner().subscribe(() => {
    this._instance.login(options).then(() => {
      if (this._loadUserProfileAtStartUp) {
        this.loadUserProfile();
      }
      //      this.alertService.dismissAllPending();
    });
    //});
  }

  /**
   * Redirects to logout.
   *
   * @param redirectUri
   * Specifies the uri to redirect to after logout.
   * @returns
   * A void Promise if the logout was successful, cleaning also the userProfile.
   */
  public async logout(redirectUri?: string) {

    if (this.isMobile()) {
      try {
        Preferences.remove({key: 'kcTokens'}).finally(() => {
          redirectUri = this.appBaseUri + ':/' + (redirectUri ? redirectUri : '/offline');
          this._userProfile = undefined;
          //TODO: move mobile logout in capacitor
          var url = this._instance.authServerUrl + '/realms/' + this._instance.realm + '/protocol/openid-connect/logout'
            + '?post_logout_redirect_uri=' + encodeURIComponent(redirectUri)
            + '&id_token_hint=' + encodeURIComponent(this._instance.idToken);
          this._instance.logout({
            postRedirectUri: url
          })
        })
      } catch (e) {
        console.log('error while logging out', e)
      }
    } else {
      this._userProfile = undefined;
      redirectUri = this.envService.keycloak.redirectUriBrowser + (redirectUri ? redirectUri : '');
      var url = this._instance.authServerUrl + '/realms/' + this._instance.realm + '/protocol/openid-connect/logout'
        + '?post_logout_redirect_uri=' + encodeURIComponent(redirectUri)
        + '&id_token_hint=' + encodeURIComponent(this._instance.idToken);
      console.log('[keycloak ionic service] logout url: ', url);
      window.location.href = url;
    }
    return;
  }


  /**
   * Redirects to registration form. Shortcut for login with option
   * action = 'register'. Options are same as for the login method but 'action' is set to
   * 'register'.
   *
   * @param options
   * login options
   * @returns
   * A void Promise if the register flow was successful.
   */
  public async register(
    options: Keycloak.KeycloakLoginOptions = {action: 'register'}
  ) {
    await this._instance.register(options);
  }

  /**
   * Check if the user has access to the specified role. It will look for roles in
   * realm and clientId, but will not check if the user is logged in for better performance.
   *
   * @param role
   * role name
   * @param resource
   * resource name If not specified, `clientId` is used
   * @returns
   * A boolean meaning if the user has the specified Role.
   */
  isUserInRole(role: string, resource?: string): boolean {
    let hasRole: boolean;
    hasRole = this._instance.hasResourceRole(role, resource);
    if (!hasRole) {
      hasRole = this._instance.hasRealmRole(role);
    }
    return hasRole;
  }

  /**
   * Return the roles of the logged user. The allRoles parameter, with default value
   * true, will return the clientId and realm roles associated with the logged user. If set to false
   * it will only return the user roles associated with the clientId.
   *
   * @param allRoles
   * Flag to set if all roles should be returned.(Optional: default value is true)
   * @returns
   * Array of Roles associated with the logged user.
   */
  getUserRoles(allRoles: boolean = true): string[] {
    let roles: string[] = [];
    if (this._instance.resourceAccess) {
      for (const key in this._instance.resourceAccess) {
        if (this._instance.resourceAccess.hasOwnProperty(key)) {
          const resourceAccess: any = this._instance.resourceAccess[key];
          const clientRoles = resourceAccess['roles'] || [];
          roles = roles.concat(clientRoles);
        }
      }
    }
    if (allRoles && this._instance.realmAccess) {
      const realmRoles = this._instance.realmAccess['roles'] || [];
      roles.push(...realmRoles);
    }
    return roles;
  }

  /**
   * Check if user is logged in.
   *
   * @returns
   * A boolean that indicates if the user is logged in.
   */
  async isLoggedIn(): Promise<boolean> {
    try {
      if (!this._instance.authenticated) {
        return false;
      }
      try {
        await this.updateToken(20);
      } catch (e) {
        console.log('[keycloak js] isLoggedIn error. update token error', e);
      }
      return true;
    } catch (error) {
      console.log('[keycloak js] isLoggedIn error', error);
      return false;
    }
  }

  /**
   * Returns true if the token has less than minValidity seconds left before
   * it expires.
   *
   * @param minValidity
   * Seconds left. (minValidity) is optional. Default value is 0.
   * @returns
   * Boolean indicating if the token is expired.
   */
  isTokenExpired(minValidity: number = 0): boolean {
    return this._instance.isTokenExpired(minValidity);
  }

  /**
   * If the token expires within minValidity seconds the token is refreshed. If the
   * session status iframe is enabled, the session status is also checked.
   * Returns a promise telling if the token was refreshed or not. If the session is not active
   * anymore, the promise is rejected.
   *
   * @param minValidity
   * Seconds left. (minValidity is optional, if not specified 5 is used)
   * @returns
   * Promise with a boolean indicating if the token was succesfully updated.
   */
  public async updateToken(minValidity = 5) {
    // TODO: this is a workaround until the silent refresh (issue #43)
    // is not implemented, avoiding the redirect loop.
    if (this._silentRefresh) {
      if (this.isTokenExpired()) {
        throw new Error(
          'Failed to refresh the token, or the session is expired'
        );
      }

      return true;
    }

    if (!this._instance) {
      throw new Error('Keycloak Angular library is not initialized.');
    }

    return this._instance.updateToken(minValidity);
  }

  /**
   * Loads the user profile.
   * Returns promise to set functions to be invoked if the profile was loaded
   * successfully, or if the profile could not be loaded.
   *
   * @param forceReload
   * If true will force the loadUserProfile even if its already loaded.
   * @returns
   * A promise with the KeycloakProfile data loaded.
   */
  public async loadUserProfile(forceReload = false) {
    if (this._userProfile && !forceReload) {
      return this._userProfile;
    }

    if (!this._instance.authenticated) {
      throw new Error(
        'The user profile was not loaded as the user is not logged in.'
      );
    }

    return this._userProfile = await this._instance.loadUserProfile();
  }

  /**
   * Returns the authenticated token, calling updateToken to get a refreshed one if necessary.
   */
  public async getToken() {
    await this.updateToken(10);
    return this._instance.token;
  }

  /**
   * Returns the logged username.
   *
   * @returns
   * The logged username.
   */
  public getUsername() {
    if (!this._userProfile) {
      throw new Error('User not logged in or user profile was not loaded.');
    }

    return this._userProfile.username;
  }

  /**
   * Clear authentication state, including tokens. This can be useful if application
   * has detected the session was expired, for example if updating token fails.
   * Invoking this results in onAuthLogout callback listener being invoked.
   */
  clearToken(): void {
    this._instance.clearToken();
  }

  /**
   * Adds a valid token in header. The key & value format is:
   * Authorization Bearer <token>.
   * If the headers param is undefined it will create the Angular headers object.
   *
   * @param headers
   * Updated header with Authorization and Keycloak token.
   * @returns
   * An observable with with the HTTP Authorization header and the current token.
   */
  public addTokenToHeader(headers: HttpHeaders = new HttpHeaders()) {
    return from(this.getToken()).pipe(
      map((token) =>
        token ? headers.set(this._authorizationHeaderName, this._bearerPrefix + token) : headers
      )
    );
  }


  /**
   * Returns the original Keycloak instance, if you need any customization that
   * this Angular service does not support yet. Use with caution.
   *
   * @returns
   * The KeycloakInstance from keycloak-js.
   */
  getKeycloakInstance(): Keycloak.KeycloakInstance {
    return this._instance;
  }

  /**
   * Returns the excluded URLs that should not be considered by
   * the http interceptor which automatically adds the authorization header in the Http Request.
   *
   * @returns
   * The excluded urls that must not be intercepted by the KeycloakBearerInterceptor.
   */
  get excludedUrls(): ExcludedUrlRegex[] {
    return this._excludedUrls;
  }

  /**
   * Flag to indicate if the bearer will be added to the authorization header.
   *
   * @returns
   * Returns if the bearer interceptor was set to be disabled.
   */
  get enableBearerInterceptor(): boolean {
    return this._enableBearerInterceptor;
  }

  /**
   * Keycloak subject to monitor the events triggered by keycloak-js.
   * The following events as available (as described at keycloak docs -
   * https://www.keycloak.org/docs/latest/securing_apps/index.html#callback-events):
   * - OnAuthError
   * - OnAuthLogout
   * - OnAuthRefreshError
   * - OnAuthRefreshSuccess
   * - OnAuthSuccess
   * - OnReady
   * - OnTokenExpire
   * In each occurrence of any of these, this subject will return the event type,
   * described at {@link KeycloakEventType} enum and the function args from the keycloak-js
   * if provided any.
   *
   * @returns
   * A subject with the {@link KeycloakEvent} which describes the event type and attaches the
   * function args.
   */
  get keycloakEvents$(): Subject<KeycloakEvent> {
    return this._keycloakEvents$;
  }

  public getLoginToken(): Promise<any> {
    return new Promise((resolve, reject) => {
      let kcTokens: KcTokens = {
        accessToken: '',
        refreshToken: '',
        idToken: ''
      };
      Preferences.get({key: 'kcTokens'}).then(kcToken => {
        kcTokens = JSON.parse(kcToken.value);
      });
      resolve(kcTokens);
    });
  }

  public setLoginToken(tokens: KcTokens): Promise<any> {

    return new Promise<any>((resolve, reject) => {
      Preferences.set({key: 'kcTokens', value: JSON.stringify(tokens)}).then(
        () => console.log('[login] success: keycloak tokens Stored!'),
        error => console.error('[login] on success, error storing item', error)
      );
      this.initWithTokens(tokens).then(kcOptions => {
        console.log('[keycloak service] kc opts', kcOptions);
        if (kcOptions) {
          this.init(kcOptions, true);
        }
      })

    })
  }

  initWithTokens(tokens: KcTokens): Promise<any> {
    return new Promise<any>((resolve, reject) => {

      if (tokens) {
        let kcOptions: KeycloakOptions = {
          config: {
            url: this.envService.keycloak.url,
            realm: this.envService.keycloak.realm,
            clientId: this.envService.keycloak.clientId
          },
          initOptions: {
            responseMode: 'query',
            enableLogging: true,
            token: tokens.accessToken,
            refreshToken: tokens.refreshToken,
            idToken: tokens.idToken,
            timeSkew: 360,
            checkLoginIframe: false,
            onLoad: 'check-sso',
          }
        }

        this.platform.ready().then(() => {
          if (this.isMobile()) {
            kcOptions.initOptions.redirectUri = this.appBaseUri + '://home?fromLogin=1';
            kcOptions.initOptions.adapter = 'capacitor';
            console.log('[keycloak ionic service] init mobile mode', kcOptions.initOptions);
          } else if (this.platform.platforms().includes('desktop') || this.platform.platforms().includes('mobileweb')) {
            kcOptions.initOptions.onLoad = 'check-sso';
            kcOptions.initOptions.adapter = 'default';
            //kcOptions.initOptions.silentCheckSsoRedirectUri = window.location.origin + '/assets/silent-check-sso.html';
            kcOptions.initOptions.redirectUri = this.envService.keycloak.redirectUriBrowser;
            console.log('[keycloak ionic service] init desktop mode', kcOptions.initOptions);
          }
          resolve(kcOptions);
        });
      }
    })
  }

  initAuth() {
    var globalP = new Promise<any>((gResolve, gReject) => {
      var pp = new Promise<any>((resolve, reject) => {
        let finalToken: any = {
          token: null,
          refreshToken: null,
          idToken: null,
        };
        try {
          Preferences.get({key: 'kcTokens'}).then((data) => {
            let finalToken: any = {
              accessToken: null,
              refreshToken: null,
              idToken: null,
            }
            try {
              finalToken = JSON.parse(data.value);
            } catch (e) {
              console.log('error while parsing tokens string from storage', e, data.value)
            }
            if (!finalToken) {
              finalToken = {
                accessToken: null,
                refreshToken: null,
                idToken: null,
              }
            }
            resolve(finalToken);
          });
        } catch (e) {
          console.log('error while getting token. resolving to empty', e)
          resolve(finalToken)
        }
      })
      pp.then((tokens) => {
        console.log('[keycloak ionice service] resolved tokens: ', tokens)
        let kcOptions: KeycloakOptions = {
          config: {
            url: this.envService.keycloak.url,
            realm: this.envService.keycloak.realm,
            clientId: this.envService.keycloak.clientId
          },
          initOptions: {
            responseMode: 'query',
            enableLogging: true,
            token: tokens.accessToken,
            refreshToken: tokens.refreshToken,
            idToken: tokens.idToken,
            timeSkew: 3600,
            checkLoginIframe: false,
            onLoad: 'check-sso',
          }
        }
        this.platform.ready().then(() => {
          if (this.isMobile()) {
            kcOptions.initOptions.redirectUri = this.appBaseUri + '://home?fromLogin=1';
            kcOptions.initOptions.adapter = 'capacitor';
            kcOptions.initOptions.onLoad = 'check-sso';
            console.log('[keycloak ionic service] init mobile mode', kcOptions);
          } else if (this.platform.platforms().includes('desktop') || this.platform.platforms().includes('mobileweb')) {
            kcOptions.initOptions.onLoad = 'check-sso';
            kcOptions.initOptions.adapter = 'default';
            kcOptions.initOptions.silentCheckSsoRedirectUri = window.location.origin + '/assets/silent-check-sso.html';
            kcOptions.initOptions.redirectUri = this.envService.keycloak.redirectUriBrowser;
            console.log('[keycloak ionic service] init desktop mode', kcOptions);
          }
          this.init(kcOptions);
          console.log('[keycloak ionic service] init keycloak done. resolving')
          gResolve('');
        }, (err) => {
          console.log('[keycloak ionic service] tokens not resolved error', err);
          gResolve('');
        });
      });

    })
    return globalP;
  }

  loginWithHint(loginHint?: any, url?: any): void {
    console.log('LOGIN : ', loginHint);
    if (this.isMobile()) {
      console.log('[keycloak ionic service] login mobile mode');
      this._instance.login({
        maxAge: 0,
        loginHint: loginHint,
        redirectUri: url
      })
    } else {
      window.open(this._instance.createLoginUrl({maxAge: 0, loginHint: loginHint}), '_self');
    }
  }
}
