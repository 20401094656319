import { Pipe, PipeTransform } from '@angular/core';
import { InvitationStatus } from '@cdq/api';

@Pipe({
  name: 'invitationStatus'
})
export class InvitationStatusPipe implements PipeTransform {
  transform(value: InvitationStatus | any): string {
    switch (value) {
      case 'SENT':
        return 'Envoyé';
      case 'DOWNLOADED':
        return 'Inscrit';
      default:
        return value;
    }
  }
}
