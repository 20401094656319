
import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import { SecurityService } from "@cdq/api";
import { MenuController, Platform } from "@ionic/angular";
import { Router } from "@angular/router";
import {KeycloakIonicService} from 'src/app/services/keycloak/keycloak-ionic.service';
import {NavigationService} from 'src/app/services/navigation/navigation.service';
import {EnvService} from 'src/app/services/environment/env.service';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
})


export class NavigationComponent implements OnInit {

    constructor(
        public navigationService: NavigationService,
        private keycloakService: KeycloakIonicService,
        private securityService: SecurityService,
        private envService: EnvService,
        private menu: MenuController,
        private router: Router,
        public platform: Platform,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        this.navigationService.setChangeDetector(this.changeDetectorRef);
        this.refresh();
    }

    refresh() {
        this.navigationService.refresh(false, 'OFFLINE');
    }

    logout(): void {
        this.keycloakService.logout()
    }

    login(): void {
        this.keycloakService.login()
    }

    async closeMenu(): Promise<void> {
        await this.menu.close('main-content')
    }

    backToHome() {
        if (this.platform.platforms().includes('desktop'))
            this.router.navigateByUrl('/home-web').catch(e => {
                console.log(e)
            })
        else
            this.router.navigateByUrl('/').catch(e => {
                console.log(e)
            })
        this.menu.close('main-content').catch(e => {
            console.log(e)
        })
    }

    navigate(url: string) {
        console.log(url)
        this.router.navigateByUrl(url, {
            replaceUrl: true,
        })
    }
    goToRiverain(): void {
        window.open(this.envService.frontCdq, '_blank');
    }
}
