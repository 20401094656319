import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import { Platform } from '@ionic/angular';
import {Observable} from "rxjs";
import { SecurityService } from '@cdq/api';
import {KeycloakIonicService} from "../keycloak/keycloak-ionic.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  userRole: string
  isConnected: boolean = false;
  isWeb: boolean = false;

  constructor(
      private keycloakService : KeycloakIonicService,
      private router: Router,
      private securityService : SecurityService,
      public platform: Platform
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Promise<any> {

    return new Promise<any>((resolve, reject) => {
      this.keycloakService.isLoggedIn().then(connected => {
        if(connected) {
          resolve(true);
        } else {
          resolve(false);
        }
      }).catch(error => reject(error));
    });
  }
}