import {ChangeDetectorRef, Injectable, NgZone} from '@angular/core';
import {Router} from "@angular/router";
import { Platform } from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  public vendorPages: Array<{
    title: string,
    icon: string,
    url: string;
  }>

  public clientPages: Array<{
    title: string,
    icon: string,
    url: string;
  }>

  public offlinePages: Array<{
    title: string,
    icon?: string,
    url?: string,
    action?: any,
  }>

  isA: string = '/home-web'

  userRole: string = ''

  countNotifications: number = 0
  vendorVersion: boolean = false

  isHomeVendor: boolean = false
  isLoggedIn: boolean = false

  private changeDetectorRef: ChangeDetectorRef;


  constructor(
    private router: Router,
    public platform: Platform,
    private ngZone: NgZone,
    ) {
  }

  setChangeDetector(ch: ChangeDetectorRef){
    this.changeDetectorRef = ch;
  }

  showNavigation() {
    if (this.userRole == 'VENDOR') {
      if(this.platform.platforms().includes('desktop')) {
        this.vendorPages = [
          { title: 'Navigation', icon: 'clipboard', url: '/connected-vendor'},
          { title: 'Mes offres', icon: 'grid', url: '/vendors/vendor-offers' },
          { title: 'Mon profil', icon: 'person', url: '/vendors/vendor-options/profile' },
          { title: 'Mon commerce', icon: 'storefront', url: '/vendors/vendor-options/boutiques' },
          { title: 'Mon portefeuille', icon: 'wallet', url: '/vendors/vendor-options/portefeuille' },
          { title: 'Mes parrainages', icon: 'person-add', url: '/vendors/vendor-options/parrainages' },
          { title: 'Contact', icon: 'call', url: '/vendors/vendor-options/contact' },
        ]

      } else {
        this.vendorPages = [
          { title: 'Mes offres', icon: 'grid', url: '/vendors/vendor-offers' },
          { title: 'Mon profil', icon: 'person', url: '/vendors/vendor-options/profile' },
          { title: 'Mon commerce', icon: 'storefront', url: '/vendors/vendor-options/boutiques' },
          { title: 'Mon portefeuille', icon: 'wallet', url: '/vendors/vendor-options/portefeuille' },
          { title: 'Mes parrainages', icon: 'person-add', url: '/vendors/vendor-options/parrainages' },
          { title: 'Contact', icon: 'call', url: '/vendors/vendor-options/contact' },
        ]
      }
    }
  }

  refresh(loggedIn: boolean, userRole: string) {
    this.userRole = userRole;
    this.ngZone.run(() => {
      if(loggedIn) {
        this.isLoggedIn = true;
        this.showNavigation();
      } else {
        this.userRole = 'OFFLINE'
        this.offlinePages = [
          //{ title: 'Accueil', icon: 'home', url: '/offline/home-web'},
          // { title: 'Inscription', icon: 'person-add', url: '/offline/inscription'},
          // { title: 'Connexion', icon: 'log-in', url: '/offline/login'},
        ]
        this.changeDetectorRef.detectChanges();
      }
    })
  }
}
