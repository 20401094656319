import {Injectable} from '@angular/core';
import {AlertController, LoadingController} from '@ionic/angular';
import {Observable, from} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {LoaderProvider} from 'src/app/components/loader-provider/loader.provider';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  loader: any;
  spinner: any;
  alert: any;

  loaderElement: HTMLIonLoadingElement;

  constructor(
    public alertController: AlertController,
    public loadingCtrl: LoadingController,
    public loaderProvider: LoaderProvider,

  ) { }

  async confirmAlert(text: string, btnName?: string, cssClass?: string): Promise<void> {
    const alert = await this.alertController.create({
      cssClass: cssClass || 'alert-basic',
      message: text,
      buttons: [
        {
          text: btnName || 'OK',
          role: 'cancel',
        }
      ]
    })
    await alert.present();
  }

  async temp(text: string, time: number, cssClass?: string, action?: () => void): Promise<void> {
    const alert = await this.alertController.create({
      cssClass: cssClass || 'alert-basic',
      header: 'Information',
      message: text,
    });

    await alert.present();

    setTimeout(() => {
      if (action != null) {
        action;
      }
      alert.dismiss();
    }, time);
  }

  async closeAlert() {
    this.alert.dismiss();
  }

  async infos(
    title: string,
    text: string,
    btnName: string,
    action?: () => void, cssClass?: string
  ): Promise<void> {
    this.alert = await this.alertController.create({
      cssClass: cssClass || 'alert-basic',
      header: title,
      message: text,
      buttons: [
        {
          text: btnName,
          handler: action,
        },
      ],
    });
    await this.alert.present();
  }

  async ask(
    title: string,
    text: string,
    btnValid: string,
    actionValid: () => void,
    btnError: string,
    actionError: () => void, cssClass?: string
  ): Promise<void> {
    const alert = await this.alertController.create({
      cssClass: cssClass || 'alert-basic',
      header: title,
      message: text,

      buttons: [
        {
          text: btnError,
          role: 'cancel',
          handler: actionError,
        },
        {
          text: btnValid,
          handler: actionValid,
        },
      ],
    });
    await alert.present();
  }



  async confirm(
    title: string,
    text: string,
    btnName: string,
    action: () => void,
    BtnCancelName?: string,
  ): Promise<void> {
    const alert = await this.alertController.create({
      cssClass: 'alert-basic',
      header: title,
      message: text,
      buttons: [
        {
          text: BtnCancelName || 'Retour',
          role: 'cancel',
          handler: () => {
            console.log('Annulé');
          },
        },
        {
          text: btnName,
          handler: action,
        },
      ],
    });
    await alert.present();
  }

  async photos(
    btnName: string,
    action: () => void,
    btnName2: string,
    action2: () => void
  ) {
    const alert = await this.alertController.create({
      header: 'Choisir par',
      cssClass: 'alert-basic',
      buttons: [
        {
          text: btnName,
          handler: action,
        },
        {
          text: btnName2,
          handler: action2,
        },
      ],
    });
    await alert.present();
  }

  async closeLoader() {
    this.loaderProvider.hideLoader();
  }

  async dismissAllPending() {
    this.loaderProvider.hideLoader();
  }

  async load(): Promise<void> {
    var pp = new Promise<void>((res, rej) => {
      this.loaderProvider.showLoader();
      res();
    });
    return pp;
  }

  loadNoAsync(): Promise<void> {
    this.loaderProvider.showLoader();
    return null;
  }

  async loadWithMessage(msg: string): Promise<void> {
    var pp = new Promise<void>((res, rej) => {
      this.loaderProvider.showLoader(msg);
      res();
    });
    return pp;
  }

  async success(title: string, text: string) {
    var pp = new Promise<void>((res, rej) => {
      this.loaderProvider.showLoader();
      res();
    });
    return pp;
  }

}
