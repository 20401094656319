import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {defineCustomElements} from '@stripe-elements/stripe-elements/loader';
import * as Sentry from "@sentry/angular";
import {gitVersion} from 'src/environments/git-version';
import {BrowserTracing} from "@sentry/tracing";
import {defineCustomElements as defineCustomElementsPWA} from '@ionic/pwa-elements/loader';

Sentry.init(
  {
    dsn: 'https://f246c760a9db4378b2510a8be94d1001@o1054632.ingest.sentry.io/4504413493264384',
    release: 'cdq-pro@' + gitVersion.branch,
    dist: gitVersion.commit,
    tracesSampleRate: 1.0,
    debug: false,
    enabled: false,
    ignoreErrors: [],
    environment: 'testing',
    integrations: [
      new BrowserTracing({
        tracingOrigins: ['localhost:9191', window['__env'].apiUrl],
      }),
    ]
  }
);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then(() => defineCustomElements(window))
  .catch(err => console.log(err));


defineCustomElementsPWA(window);

