import {Component, Input, OnInit} from '@angular/core';
import {OpenPeriod} from '@cdq/api';
import {ModalController} from '@ionic/angular';
import * as moment from 'moment';

@Component({
  selector: 'app-datetime',
  templateUrl: './datetime.component.html',
  styleUrls: ['./datetime.component.scss']
})
export class DatetimeComponent implements OnInit {

  @Input('type') type: string;
  @Input('day') day: number;
  @Input('element') element: any;
  // time: any;
  // start: any;
  // end: any;
  // model: any;


  segment: 'start' | 'end' = 'start';
  startTime: any;
  endTime: any;

  constructor(
    private modalCtrl: ModalController,
  ) { }

  ngOnInit(): void {
    console.log(this.type, this.day, this.element);
    let time = this.element.split(' - ');
    this.startTime = moment(time[0], 'HH:mm').locale('fr').toISOString(true);
    this.endTime = moment(time[1], 'HH:mm').locale('fr').toISOString(true);
  }

  dismissAllDay(): void {
    let object = {
      start: "24H",
      end: "24",
    }
    this.modalCtrl.dismiss(object);
  }

  dismissModal(): void {

    if(this.segment == 'start') {
      this.segment = 'end';
    } else if(this.segment == 'end') {
      let object = {
        start: moment(this.startTime).format('HH:mm'),
        end: moment(this.endTime).format('HH:mm'),
      }
      this.modalCtrl.dismiss(object);
    }
  }

  dismiss(): void {
    this.modalCtrl.dismiss();
  }
}
