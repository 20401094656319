import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateManagerService {

  /**
   * MONTHS FRENCH VALUES
   */
  MONTH : string[] = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre"
  ]
  /**
   * DAYS FRENCH VALUES
   */
  DAYS : string[] = [
    "Dimanche",
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi"
  ]
  /**
   * DAYS NBR FOR EACH MONTH
   */
  NB_DAYS : number[] = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
  NB_DAYS_B : number[] = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

  constructor() { }

  /**
   * To show string value for date (dueDate)
   * @param date {number[]}
   */
  displayDateFromArray(date : any) : string {
    let str : string = `${date[2]} ${this.MONTH[date[1] - 1]} ${date[0]}`
    return str
  }

  /**
   * To show string value for date (dueDate)
   * @param date {string} 2021-01-08T17:53:46.728Z
   */
  displayDateFromString(date : any) : string {
    let str : string = `${date.substr(8, 2)} ${this.MONTH[parseInt(date.substr(5, 2)) - 1]} ${date.substr(0, 4)}`
    return str
  }


  /**
   * To show string with hours value for date (dueDate)
   * @param date {number[]}
   */
  displayDateAndHoursFromArray(date : any) : string {
    let str : string = `${date[2]} ${this.MONTH[date[1] - 1]} ${date[0]}, ${date[3] < 10 ? '0'+date[3] : date[3]}:${date[4] < 10 ? '0'+date[4] : date[4]}`
    return str
  }

  displayDateAndHours(date : any) : string {
    let dateObject : Date = new Date(date)

    let str : string = `${dateObject.getDate()} ${this.MONTH[dateObject.getMonth()]} ${dateObject.getFullYear()}`
    str += `, ${dateObject.getHours() < 10 ? '0' + dateObject.getHours() : dateObject.getHours()}:${dateObject.getMinutes() < 10 ? '0'+dateObject.getMinutes() : dateObject.getMinutes()}`

    return str
  }

  convertTZ(date: any, tzString: string) {
    return new Date((typeof date == "string" ? new Date(date) : date).toLocaleString("fr-EU", {timeZone: tzString}))
  }

  /**
   * Return true if date is before today
   * @param date {string}
   */
  isEnded(date : string) : boolean {
    let result : boolean = true

    if (date != null) {
      let endDate = new Date(date)
      let today = new Date()
      let diff = (endDate.getTime() - today.getTime()) / 1000

      if (diff > 0)
        result = false

    }

    return result
  }

  stringDiffWithCurrent(date : string) : string {

    
    let current : Date = new Date()
    let dateObj : Date = new Date(date)
    let diffSecond: number = (dateObj.getTime() - current.getTime()) / 1000

    let saveMinutes = diffSecond / 60
    let minutes = saveMinutes % 60
    let saveHours = saveMinutes / 60
    let hours = saveHours % 24
    let days = saveHours / 24

    let result : string = ''

    if (diffSecond > 0) {
      result += days > 0 ? (days == 1 ? '1 jour' : `${days.toFixed(0)} jours`) : ''
      result += hours > 0 ? (hours == 1 ? ' 1 heure' : ` ${hours.toFixed(0)} heures`) : ''
      result += (minutes > 30 && days == 0) || (days == 0 && minutes > 1) ? ` ${minutes.toFixed(0)} minutes` : ''
      result = minutes < 15 && hours == 0 && days == 0 ? 'Moins de 15 minutes' : result
      result = minutes < 30 && hours == 0 && days == 0 ? 'Moins de 30 minutes' : result
      result = minutes < 60 && hours == 0 && days == 0 ? 'Moins d\'une heure' : result
    }
    else {
      result = 'Termineée'
    }

    return result

  }

  getDiffInMinutes(start : string, end : string) : number {
    let startDate = new Date(start)
    let endDate = new Date(end)

    let diff = endDate.getTime() - startDate.getTime()

    return parseInt((diff / 60000).toFixed(0))
  }
}
