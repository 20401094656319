import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {EnvService} from "../environment/env.service";
import {AccountType} from '@cdq/api';

@Injectable({
  providedIn: 'root'
})
export class UploadFileService {

  constructor(
      private httpClient: HttpClient,
      private envService: EnvService
  ) { }

  public privateUploadService(fileName: string, assetId: number, assetType: string, file: any) {
    const headers = new HttpHeaders()
        .append('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    formData.append('assetType', new Blob([
      assetType
    ], {
      type: "text/plain"
    }));
    formData.append('assetId', new Blob([
      '' + assetId
    ], {
      type: "text/plain"
    }));
    formData.append('filename', new Blob([
      fileName
    ], {
      type: "text/plain"
    }));
    formData.append('mimeType', new Blob([
      file.type
    ], {
      type: "text/plain"
    }));

    formData.append('file', file);

    const serverUrl = this.envService.apiUrl + '/api/v1/private-assets/upload'

    return this.httpClient.post<any>(serverUrl, formData, {
      reportProgress: true,
      observe: 'events'
    })
  }

  public upload(fileName: string, assetId: number, assetType: string, file: any) {

    const headers = new HttpHeaders()
        .append('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    formData.append('assetType', new Blob([
      assetType
    ], {
      type: "text/plain"
    }));
    formData.append('assetId', new Blob([
      '' + assetId
    ], {
      type: "text/plain"
    }));
    formData.append('filename', new Blob([
      fileName
    ], {
      type: "text/plain"
    }));
    formData.append('mimeType', new Blob([
      'image/png'
    ], {
      type: "text/plain"
    }));
    formData.append('file', file)
    // const profileType = userRole == 'CLIENT' ? 'parent' : 'babysitter'
    // const serverUrl = this.envService.apiUrl + '/api/v1/' + profileType.toLowerCase() + '/profile/upload-file/' + fileType;

    console.log(formData.getAll('file'))
    console.log(formData.getAll('mimeType'))
    console.log(formData.getAll('file'))

    const serverUrl = this.envService.apiUrl + '/api/v1/assets/upload'
    /*return this.httpClient.post<any>(serverUrl, formData, {
      reportProgress: true,
      observe: 'events'
    })*/
    return this.httpClient.post<any>(serverUrl, formData);
  }

  public uploadEmail(fileName: string, file: any, mimeType: string, userType?: AccountType) {

    const headers = new HttpHeaders()
        .append('Content-Type', 'multipart/form-data');

    const formData = new FormData();

    formData.append('userType', userType);
    formData.append('mimeType', new Blob([
      '' + mimeType
    ], {
      type: "text/plain"
    }));
    formData.append('filename', new Blob([
      fileName
    ], {
      type: "text/plain"
    }));
    formData.append('file', file)
    // const profileType = userRole == 'CLIENT' ? 'parent' : 'babysitter'
    // const serverUrl = this.envService.apiUrl + '/api/v1/' + profileType.toLowerCase() + '/profile/upload-file/' + fileType;

    console.log(formData.getAll('file'))
    console.log(formData.getAll('mimeType'))

    const serverUrl = this.envService.apiUrl + '/api/v1/sponsor/upload-vendor-contact'
    /*return this.httpClient.post<any>(serverUrl, formData, {
      reportProgress: true,
      observe: 'events'
    })*/
    return this.httpClient.post<any>(serverUrl, formData);
  }

  public uploadClientContact(fileName: string, file: any, mimeType: string) {

    const headers = new HttpHeaders()
        .append('Content-Type', 'multipart/form-data');

    const formData = new FormData();

    formData.append('mimeType', new Blob([
      '' + mimeType
    ], {
      type: "text/plain"
    }));
    formData.append('filename', new Blob([
      fileName
    ], {
      type: "text/plain"
    }));
    formData.append('file', file)
    console.log(formData.getAll('file'))
    console.log(formData.getAll('mimeType'))

    const serverUrl = this.envService.apiUrl + '/api/v1/crm/upload-client-contact'
    return this.httpClient.post<any>(serverUrl, formData);
  }

}
