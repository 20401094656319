import {Injectable, NgZone} from '@angular/core';
import {ActionPerformed, PushNotifications, PushNotificationSchema, Token} from "@capacitor/push-notifications";
import {Device} from "@capacitor/device";
import {SecurityService} from "@cdq/api";
import {Platform, ToastController} from "@ionic/angular";
import {Router} from "@angular/router";
import {Preferences} from "@capacitor/preferences";
import {App} from '@capacitor/app';
import {
  Channel,
  LocalNotifications,
  ScheduleOptions,
  ActionPerformed as LocalActionPerformed
} from "@capacitor/local-notifications";
import {LocalNotificationSchema} from "@capacitor/local-notifications/dist/esm/definitions";
import {AlertService} from "../alert/alert.service";


@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  // localNotification = ['notif 1', 'notif 2', 'notif 3']

  private fcmToken: string;

  private appBaseUri: string = 'cdqapppro';

  private notifId: number = 1;

  private androidChannelId: string;

  public tapOpened: boolean = false;
  public tapUrl: string = '';

  localNotification = {
    image: '',
    title: '',
    body: ''
  }

  listOfNotifications = []

  empty: boolean = false
  count: number = 0

  constructor(private securityService: SecurityService,
              public platform: Platform,
              private ngZone: NgZone,
              private toastCtrl: ToastController,
              private alertSvc: AlertService,
              private router: Router) {
    this.initNotifications();
  }

  checkNotification(): boolean {
    if (this.listOfNotifications.length > 0) {
      this.empty = false
    } else {
      this.empty = true
    }
    return this.empty
  }

  countNotification(): number {
    return this.count = this.listOfNotifications.length
  }

  storeNotification(notification): void {
    this.localNotification.title = notification.notification.title
    this.localNotification.body = notification.notification.body
    this.localNotification.image = notification.notification.image
    this.listOfNotifications.push(this.localNotification)
  }

  listNotification(): any {
    return this.listOfNotifications
  }

  clearNotification(notification): void {
    this.listOfNotifications.forEach(r => {
      if (notification == r) {
        this.listOfNotifications = this.listOfNotifications.filter((value) => value != notification);
      }
    })
  }

  clearAll(): any {
    this.listOfNotifications = []
    return this.localNotification
  }

  initNotifications() {
    //handle first open
    this.updateDeviceToken();

    if (this.platform.platforms().includes("mobile") && !this.platform.platforms().includes("mobileweb")) {

      PushNotifications.addListener(
        "pushNotificationReceived",
        async (notification: PushNotificationSchema) => {
          console.log("[CDQ Notification Handler] Push received: ", notification);
          const data = notification.data
          //this.storeNotification(notification.body)
          this.forwardNotification(notification);
        }
      );
      // Method called when tapping on a notification
      PushNotifications.addListener(
        "pushNotificationActionPerformed",
        async (notification: ActionPerformed) => {
          console.log("[CDQ Notification Handler] Push Action Performed: ", notification);
          //Exemple : Redirect to offerId
          this.ngZone.run(() => {
            this.loadOfferFromTap(notification);
          })
        });

      /*App.addListener("appUrlOpen", (data: any) => {
          console.log("App opened with URL: " + data.appUrl);
          this.router.navigateByUrl(data.appUrl);
      });*/
      console.log('push notification requested')
      let me = this;
      PushNotifications.addListener(
        "registration",
        (token: Token) => {
          this.fcmToken = token.value;
          this.updateDeviceToken();
          Preferences.set({key: "fcmToken", value: token.value}).then(
            () => {
              console.log("fcm push token received", token);
            },
            (error) => console.error("error while storing fcm token", error)
            //record device
          );
        }
      );

      PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
          console.log('notifications authorized')
        } else {
          console.log('error while requestion permission', result)
        }
      });

      PushNotifications.addListener("registrationError", (error: any) => {
        console.log("Error on registration: " + JSON.stringify(error));
      });

      if (this.platform.platforms().includes("android") && this.platform.platforms().includes("mobile") && !this.platform.platforms().includes("mobileweb")) {
        const notificationChannel: Channel = {
          id: 'cdq-android-notifications',// id must match android/app/src/main/res/values/strings.xml's default_notification_channel_id
          name: 'Oui Love Locals',
          description: '',
          importance: 4,
          visibility: 1
        };
        LocalNotifications.createChannel(notificationChannel);

        LocalNotifications.addListener(
          "localNotificationActionPerformed",
          async (notification: LocalActionPerformed) => {
            console.log("[CDQ Notification Handler] Android Local Action Performed: ", notification);
            //Exemple : Redirect to offerId
            this.ngZone.run(() => {
              this.loadOfferFromTap(notification);
            })
          });
      }
    }
  }

  checkNotificationPermissions() {
    PushNotifications.checkPermissions().then((value) => {
      if (value.receive != 'granted') {
        this.alertSvc.confirm('Information',
          'Les notifications sont nécéssaires pour profiter pleinement de notre application. Activer les notifications ?',
          'Oui', () => {
            /*this.openNativeSettings.open('notification_id').then((value) => {
                console.log(value);
                this.alertSvc.dismissAllPending();
            })*/
          }, 'Non');
      }
    })
  }

  loadOfferFromTap<Type extends LocalActionPerformed | ActionPerformed>(notification: Type) {
    this.alertSvc.load();
    try {
      // @ts-ignore
      const data = (notification as any).notification.extra ?
        (notification as any).notification.extra :
        (notification as any).notification.data;
      console.log('opening offer from ' + typeof (notification), notification)
      console.log('opening offer data ' + typeof (notification), data)
      if (data.appUrl) {
        this.tapOpened = true;
        let url = data.appUrl;
        if (url.indexOf(this.appBaseUri + '://') == 0) {
          url = url.substr(8);
        }
        setTimeout(() => {
          this.ngZone.run(() => {

            if (this.router.url.endsWith(url)) {
              console.log('route ending with tapped url', this.router.url);
              this.tapOpened = false;
              this.tapUrl = url;
            } else {
              this.router.navigateByUrl(url, {
                state: {
                  type: 'notification',
                },
              }).then(value => {
                console.log('reseting tap open');
                this.tapOpened = false;
                this.tapUrl = url;
              });
            }
          })
        }, 2000);
      }
    } catch (e) {
      console.log('error while navigating from notif', e)
      this.alertSvc.closeLoader();
    } finally {

    }
  }

  forwardNotification(notification: any) {
    let notif: LocalNotificationSchema = {
      id: this.notifId++,
      title: notification.data.title,
      body: notification.data.body,
      extra: {},
      channelId: 'cdq-android-notifications',
    };
    if (notification.data.imageLink) {
      notif.attachments = [{id: 'offerid', url: notification.data.imageLink}];
    }
    if (notification.data.appUrl) {
      if (notification.data.appUrl.indexOf(this.appBaseUri + '://') == 0) {
        notif.extra.appUrl = notification.data.appUrl.substr(8);
      } else {
        notif.extra.appUrl = notification.data.appUrl;
      }

    }

    if (this.platform.platforms().includes("android") && this.platform.platforms().includes("mobile") && !this.platform.platforms().includes("mobileweb")) {
      LocalNotifications.schedule({notifications: [notif]});
    }

  }

  updateDeviceToken() {
    try {
      var me = this;
      if (this.platform.platforms().includes("mobile") && !this.platform.platforms().includes("mobileweb")) {
        App.getInfo().then(function (appInfo) {
          Device.getId().then(function (deviceIdHolder) {
            Device.getInfo().then(function (device) {
              me.securityService.recordNotificationToken({
                devicePushToken: me.fcmToken ? me.fcmToken : 'no_token_available',
                version: device.osVersion,
                os: device.operatingSystem,
                model: device.model,
                manufacturer: device.manufacturer,
                deviceName: device.name,
                appVersion: appInfo.version,
                appId: appInfo.id,
                appBuild: appInfo.build,
                appName: appInfo.name,
                deviceUuid: deviceIdHolder.uuid
              }).subscribe((r) => {
                console.log("account updated with fcm token and devic id", r);
              });
            })
          });
        });
      }


    } catch (e) {
      console.log('error while updating device token and id on API', e);
    }

  }

  updateDeviceTokenWhenLoggedId(res: any) {
    try {
      var me = this;
      if (this.platform.platforms().includes("mobile") && !this.platform.platforms().includes("mobileweb")) {

        App.getInfo().then(function (appInfo) {
          Device.getId().then(function (deviceIdHolder) {
            Device.getInfo().then(function (device) {
              me.securityService.recordNotificationTokenWithAccount({
                devicePushToken: me.fcmToken ? me.fcmToken : 'no_token_available',
                version: device.osVersion,
                os: device.operatingSystem,
                model: device.model,
                manufacturer: device.manufacturer,
                deviceName: device.name,
                appVersion: appInfo.version,
                appBuild: appInfo.build,
                appName: appInfo.name,
                appId: appInfo.id,
                deviceUuid: deviceIdHolder.uuid
              }).subscribe((r) => {
                console.log("account updated with fcm token and devic id", r);
              });
            })
          });
        });
      }
    } catch (e) {
      console.log('error while updating device token and id on API', e);
    }
  }

  listenForMessages() {
    /*this.messagingService.getMessages().subscribe(async (msg: any) => {
        console.log("New message", msg);
        console.log('Body notif', msg.notification.body)
        this.notifContent = msg
        this.notificationService.storeNotification(this.notifContent)
        this.sharedService.sendNotifEvent()
    });*/
  }

  requestPermission() {
    /*this.messagingService.requestPermission().subscribe(
        async (token) => {
            let secService = this.securityService;

            Device.getId().then((device) => {
                secService.recordNotificationToken({
                    deviceUuid: device.uuid
                }).forEach(r => {
                    console.log("uuid", device.uuid)
                });
                console.log("call uuid device done");
            }).catch(e => {
                console.log("error in call device uuid", e);
            })

            Device.getInfo().then(function (device) {
                secService.recordNotificationToken({
                    devicePushToken: token,
                    version: device.osVersion,
                    os: device.operatingSystem,
                    model: device.model,
                }).forEach(r => {
                    console.log("version", device.osVersion)
                    console.log("os", device.operatingSystem)
                    console.log("RECORD NOTIFICATION OK", r);
                });
                console.log("call done");
            }).catch(e => {
                console.log("error in call", e)
            });
        },
        async (err) => {
            const alert = await this.alertCtrl.create({
                header: "Error",
                message: err,
                buttons: ["OK"],
            });
            await alert.present();
        }
    );*/
  }
}
