import {HttpClient} from '@angular/common/http';
import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CardInfo, StripePaymentService, PaymentResourceService, SecurityService} from '@cdq/api';
import {ModalController, Platform} from '@ionic/angular';
import {AlertService} from 'src/app/services/alert/alert.service';
import {KeycloakIonicService} from 'src/app/services/keycloak/keycloak-ionic.service';
declare var Stripe: any;
import * as AppConstants from '../../app.constants';
import {EnvService} from "../../services/environment/env.service";


// import {Stripe, PaymentFlowEventsEnum} from '@capacitor-community/stripe';


@Component({
  selector: 'app-add-card',
  templateUrl: './add-card.component.html',
  styleUrls: ['./add-card.component.scss']
})
export class AddCardComponent implements OnInit {

  cardsInfos: CardInfo[]
  cardsSaved: CardInfo[] = []
  defaultCard: CardInfo

  @Input("product") product: any;

  stripe: any;
  elements: any;
  card: any;

  constructor(
    private stripePaymentService: StripePaymentService,
    private paymentService: PaymentResourceService,
    private router: Router,
    public platform: Platform,
    private alertService: AlertService,
    private securityService: SecurityService,
    private keycloakService: KeycloakIonicService,
    private modalCtrl: ModalController,
    private envService: EnvService,
    private http: HttpClient
  ) {
    this.stripe = Stripe(this.envService.stripePublicKey);

  }

  ngOnInit() {
    // this.refresh();
    this.loadCards();
    console.log(this.product);
  }

  ngAfterViewInit() {
    this.refresh();
  }

  dismissModal(): void {
    this.modalCtrl.dismiss();
  }

  selectCard(card: any): void {
    this.modalCtrl.dismiss(card);
  }

  refresh(): void {
    // Your Stripe public key

    // Create `card` element that will watch for updates
    // and display error messages
    this.elements = this.stripe.elements();
    this.card = this.elements.create('card');
    this.card.mount('#card-element');
    this.card.addEventListener('change', event => {
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });

    // Listen for form submission
    const paymentForm = document.getElementById('payment-form');
    paymentForm.addEventListener('submit', event => {
      event.preventDefault();
      this.stripe.createToken(this.card).then(result => {
        if (result.error) {
          console.log('Error creating payment method.');
          const errorElement = document.getElementById('card-errors');
          errorElement.textContent = result.error.message;
          this.alertService.closeLoader();
          this.alertService.temp(`Une erreur est survenue lors de l'ajout de votre carte, veuillez réessayez ultérieurement.`, 3000);
        } else {
          console.log('Token acquired!');
          console.log(result.token);
          console.log(result.token.id);
          this.paymentService.addCreditCard({
            cardId : result.token.id,
            defaultCard : true
          }).forEach(r => {
            console.log(r)
            this.alertService.temp(`Votre carte a bien été ajouté !`, 3000);
            this.loadCards();
          }).catch(e => {
            console.log(e)
            this.alertService.temp('Error : ' + e, 3000);
            this.modalCtrl.dismiss('fail');
          })
        }
      })
    });
  }

  // validate(): void {
  //   this.stripePaymentService.createSetupIntent().subscribe(r => {
  //     console.log("Setup Intent", r);
  //     this.stripe.confirmCardSetup(r.result, {
  //       payment_method: {
  //         card: this.card,
  //       },
  //     }).then(resCard => {
  //       console.log('SETUP INTENT RESULT', resCard);
  //       // UPDATE CUSTOMER WITH PM
  //       this.stripePaymentService.updateCustomer({
  //         defaultPaymentMethod: resCard.payment_method,
  //       }).subscribe(() => {
  //         this.modalCtrl.dismiss("success")
  //       })
  //     });
  //   })
  // }

  // useCard(cardId): void {
  //   console.log(cardId);

  //   this.stripePaymentService.createSetupIntent().subscribe(r => {
  //     console.log("Setup Intent", r);
  //     this.stripe.confirmCardSetup(r.result, {
  //       payment_method: {
  //         card: this.card,
  //       },
  //     }).then(function(result) {
  //       console.log('SETUP INTENT RESULT', result);
  //       // UPDATE CUSTOMER WITH PM
  //       this.stripePaymentService.updateCustomer({
  //         defaultPaymentMethod: result.payment_method,
  //         cardId: result
  //       }).then(() => {
  //          this.modalCtrl.dismiss('sucess');
  //       })
  //     });
  //   })
  // }

  loadCards(): void {
    this.cardsSaved = [];
    this.paymentService.listCreditCards().forEach(r => {
      console.log(r)
      this.cardsSaved = r.items;
      // this.cardsInfos = r.items
      // for (let card of this.cardsInfos) {
      //   if (card.defaultCard) {
      //     this.defaultCard = card
      //   }
      //   else {
      //     this.cardsSaved.push(card)
      //   }
      // }
    }).catch(e => {
      console.log(e)
    })
  }

  deleteCard(id: string): void {
    this.paymentService.removeCreditCard({
      cardId: id
    }).forEach(r => {
      console.log(r)
      this.loadCards()
    }).catch(e => {
      console.log(e)
    })
  }

}
