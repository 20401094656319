import {Component, HostListener, OnInit} from '@angular/core';
import {AlertController, ModalController, Platform, PopoverController} from '@ionic/angular';
import {ImporterContactsComponent} from './importer-contacts/importer-contacts.component';
import {KeycloakIonicService} from 'src/app/services/keycloak/keycloak-ionic.service';
import {TooltipInvitationStatusComponent} from './tooltip-invitation-status/tooltip-invitation-status.component';
import * as moment from 'moment';
import {CrmService} from '@cdq/api';
import {AlertService} from 'src/app/services/alert/alert.service';


@Component({
  selector: 'app-mes-contacts-clients',
  templateUrl: './mes-contacts-clients.component.html',
  styleUrls: ['./mes-contacts-clients.component.scss']
})
export class MesContactsClientsComponent implements OnInit {

  clientContacts: any[] = [];
  displayedContacts: any[] = [];
  limit: number = 20;

  constructor(
    public alertService: AlertService,
    private modalCtrl: ModalController,
    private crmService: CrmService,
    private kcService: KeycloakIonicService,
    private popoverController: PopoverController,
    private alertController: AlertController,
    public platform: Platform
  ) { }

  ngOnInit(): void {
    this.refresh();
  }

  async openContactModal() {
    const modal = await this.modalCtrl.create({
      component: ImporterContactsComponent,
      componentProps: {clientContacts: this.clientContacts}
    });

    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
        this.refresh(); // Refresh the contact list if changes were made
      }
    });

    return await modal.present();
  }

  refresh(): void {
    this.kcService.isLoggedIn().then(connected => {
      if (connected) {
        this.crmService.getClientContacts().subscribe(
          (response: any) => {
            this.clientContacts = response.result;
            this.displayedContacts = this.clientContacts.slice(0, this.limit);
          },
          (error) => {
            console.error('Error fetching client contacts:', error);
          }
        );
      }
    });
  }

  loadData(event) {
    setTimeout(() => {
      const newLimit = this.limit + 20;
      this.displayedContacts = this.clientContacts.slice(0, newLimit);
      this.limit = newLimit;
      event.target.complete();

      if (this.displayedContacts.length === this.clientContacts.length) {
        event.target.disabled = true;
      }
    }, 1000);
  }


  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    const pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
    const max = document.documentElement.scrollHeight;

    // If the user scrolls to the bottom, load more data
    if (pos === max) {
      this.loadData(event);
    }
  }

  async showPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: TooltipInvitationStatusComponent,
      showBackdrop: false,
      event: ev,
      translucent: true
    });
    return await popover.present();
  }


  async checkLastInvitationDateAndSendInvitations() {
    const contactsToInvite = this.clientContacts.filter(contact => contact.invitationStatus === 'SENT');

    if (contactsToInvite.length > 0) {
      const lastInvitationDate = this.getLastInvitationDate(contactsToInvite);
      const now = moment();
      const daysSinceLastInvitation = now.diff(lastInvitationDate, 'days');

      if (daysSinceLastInvitation < 7) {
        const alert = await this.alertController.create({
          header: 'Attention',
          message: `Nous recommandons de respecter un délai de 7 jours entre les envoies. Votre dernier email d'invitation date le ${lastInvitationDate.format('DD/MM/YYYY')}. Voulez vous renvoyer à nouveau ?`,
          buttons: [
            {
              text: 'No',
              role: 'cancel',
              handler: () => {
                console.log('User chose not to resend invitations');
              }
            },
            {
              text: 'Yes',
              handler: () => {
                this.alertService.loadWithMessage("Sauvegarde ...").then(() => {
                  this.sendInvitations(contactsToInvite);
                })
              }
            }
          ]
        });

        await alert.present();
      } else {
        this.alertService.loadWithMessage("Sauvegarde ...").then(() => {
          this.sendInvitations(contactsToInvite);
        })
      }
    } else {
      const alert = await this.alertController.create({
        header: 'Aucune invitation',
        message: 'Aucune invitation à envoyer.',
        buttons: ['OK']
      });

      await alert.present();
    }
  }

  getLastInvitationDate(contacts: any[]): moment.Moment {
    let latestDate = moment(0); // Initialize to epoch

    contacts.forEach(contact => {
      const invitationDate = moment(contact.lastInvitationDate);
      if (invitationDate.isAfter(latestDate)) {
        latestDate = invitationDate;
      }
    });

    return latestDate;
  }


  async sendInvitations(contactsToInvite: any[]) {
    const emailList = contactsToInvite.map(contact => contact.email);

    try {
      await this.crmService.sendReInvitations({emailList}).toPromise();

      const alert = await this.alertController.create({
        header: 'Invitations envoyées',
        message: 'Les invitations ont été envoyées avec succès.',
        buttons: ['OK']
      });

      await alert.present();
    } catch (error) {
      console.error('Error sending invitations:', error);

      const alert = await this.alertController.create({
        header: 'Erreur',
        message: 'Une erreur est survenue lors de l\'envoi des invitations. Veuillez réessayer plus tard.',
        buttons: ['OK']
      });

      await alert.present();
    }
  }
  getBadgeColor(status: string): string {
    switch (status) {
      case 'DOWNLOADED':
        return 'success';
      case 'SENT':
        return 'warning';
      default:
        return 'secondary';
    }
  }
}