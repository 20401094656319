import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Configuration, OAuthService } from '@cdq/api';
import { environment } from '../environments/environment';
import { NavigationComponent } from './components/navigation/navigation.component';
// import {NgcCookieConsentConfig, NgcCookieConsentModule} from "ngx-cookieconsent";
import { FormsModule } from '@angular/forms';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { Device } from '@ionic-native/device/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ChartsModule } from 'ng2-charts';
import { SuperTabsModule } from '@ionic-super-tabs/angular';
import { AgmCoreModule } from '@agm/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import * as Sentry from '@sentry/angular';
import { CommonModule, DatePipe } from '@angular/common';
import { MultiOpenPeriodPage } from './components/multi-open-period/multi-open-period.page';
import { CustomersDataPage } from './components/customers-data/customers-data.page';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import { ModalImageComponent } from './components/modal-image/modal-image.component';
import { ModalDetailsOfferComponent } from './components/modal-details-offer/modal-details-offer.component';
import {PaymentComponent} from './components/payment/payment.component';
import {AddCardComponent} from './components/add-card/add-card.component';
import {EnvService} from './services/environment/env.service';
import {EnvServiceProvider} from './services/environment/env.service.provider';
import {KeycloakIonicService} from './services/keycloak/keycloak-ionic.service';
import {initializer} from './services/keycloak/keycloak.initializer';
import {KeycloakInterceptorService} from './services/keycloak/keycloak.interceptor.service';
import { DatetimeComponent } from './components/datetime/datetime.component';
import {NotFoundComponent} from './offline/not-found/not-found.component';
import { MesContactsClientsComponent } from './vendors-web/crm/mes-contacts-clients/mes-contacts-clients.component';
import { ImporterContactsComponent } from './vendors-web/crm/mes-contacts-clients/importer-contacts/importer-contacts.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ReactiveFormsModule } from '@angular/forms';
import { InvitationStatusPipe } from './pipes/invitation-status.pipe';
import { TooltipInvitationStatusComponent } from './vendors-web/crm/mes-contacts-clients/tooltip-invitation-status/tooltip-invitation-status.component';

// const cookieConfig: NgcCookieConsentConfig = {
//   autoOpen: false,
//   cookie: {
//     domain: window.location.hostname,
//   },
//   position: "bottom-right",
//   theme: "classic",
//   palette: {
//     popup: {
//       background: "#29948d",
//       text: "#ffffff",
//       link: "#ffffff"
//     },
//     button: {
//       background: "#465555",
//       text: "#ffffff",
//       border: "transparent"
//     }
//   },
//   type: "info",
//   content: {
//     message: "En continuant sur notre site, vous acceptez l'utilisation des cookies et les ",
//     dismiss: "J'accepte",
//     deny: "Refuse cookies",
//     link: "conditions d'utilisations.",
//     href: "/terms",
//     policy: "Cookie Policy"
//   }
// }

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    MultiOpenPeriodPage,
    CustomersDataPage,
    ModalImageComponent,
    ModalDetailsOfferComponent,
    AddCardComponent,
    PaymentComponent,
    DatetimeComponent,
    NotFoundComponent,
    MesContactsClientsComponent,
    ImporterContactsComponent,
    InvitationStatusPipe,
    TooltipInvitationStatusComponent
  ],
  entryComponents: [],
  imports: [
    CommonModule,
    // IonicImageLoaderModule,
    SuperTabsModule.forRoot(),
    ChartsModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    // NgcCookieConsentModule.forRoot(cookieConfig),
    HttpClientModule,
    FormsModule,
    ServiceWorkerModule.register('combined-sw.js', {
      enabled: environment.production,
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAAr8z3OjnqnW5Wqp-r4eBWizbu6eayxk8',
      libraries: ['places'],
    }),
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatInputModule,
    Ng2SearchPipeModule,
    CommonModule,
    MatNativeDateModule,
    MatDialogModule,
    MatSelectModule,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    ReactiveFormsModule
    // NgxMatDatetimePickerModule,
    // NgxMatTimepickerModule,
    // NgxMatNativeDateModule,
  ],
  providers: [
    /*GoogleAnalytics,
    LaunchNavigator,*/
    DatePipe,
    EnvServiceProvider,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        logErrors: true,
        showDialog: false,
      }),
    },
    Device,
    BarcodeScanner,
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KeycloakInterceptorService,
      multi: true,
    },
    {
      provide: KeycloakIonicService,
    },
    {
      provide: Configuration,
      useFactory: (envService: EnvService) => {
        console.log('api: ', envService.apiUrl);
        const configBase = {
          // set configuration parameters here.
          withCredentials: true,
          basePath: envService.apiUrl,
        };
        return new Configuration(configBase);
      },
      deps: [EnvService],
      multi: false,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      deps: [EnvService, KeycloakIonicService],
      multi: true,
    },
    Deploy,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
