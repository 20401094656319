import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tooltip-invitation-status',
  templateUrl: './tooltip-invitation-status.component.html',
  styleUrls: ['./tooltip-invitation-status.component.scss']
})
export class TooltipInvitationStatusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
