import {NgModule} from '@angular/core';
import {NoPreloading, RouterModule, Routes} from '@angular/router';
import {NotFoundComponent} from './offline/not-found/not-found.component';
import {AuthGuardService} from './services/auth-guard/auth-guard.service';

const routes: Routes = [
	{
		path: '',
		redirectTo: 'offline',
		pathMatch: 'full'
	},
	{
		path: 'offline',
		loadChildren: () => import('./offline/offline.module').then((m) => m.OfflinePageModule),
	},
	{
		path: 'connected-vendor',
		loadChildren: () => import('./connected-vendor/connected-vendor.module').then(m => m.ConnectedVendorPageModule),
		canActivate: [AuthGuardService]
	},
	{
		path: 'complete-register',
		loadChildren: () => import('./complete-register/complete-register.module').then(m => m.CompleteRegisterPageModule),
		canActivate: [AuthGuardService]
	},
	{
		path: 'vendors',
		loadChildren: () => import('./vendors/vendors.module').then(m => m.VendorsPageModule),
		canActivate: [AuthGuardService],
	},
	{
		path: 'vendors-web',
		loadChildren: () => import('./vendors-web/vendors-web.module').then(m => m.VendorsWebPageModule),
		canActivate: [AuthGuardService],
	},
	{
		path: 'client-offer-detail/:id',
		loadChildren: () => import('./client-research/client-offer-detail/client-offer-detail.module').then(m => m.ClientOfferDetailPageModule)
	},
	{
		path: 'client-vendor-detail/:id',
		loadChildren: () => import('./client-research/client-vendor-detail/client-vendor-detail.module').then(m => m.ClientVendorDetailPageModule)
	},
	{
		path: 'client-vendor-offers',
		loadChildren: () => import('./client-research/client-vendor-offers/client-vendor-offers.module').then(m => m.ClientVendorOffersPageModule)
	},
	{
		path: 'not-found', component: NotFoundComponent,
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			preloadingStrategy: NoPreloading,
			scrollPositionRestoration: 'enabled',
			enableTracing: true,
			relativeLinkResolution: 'legacy'
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
