import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Component, Input, NgZone, OnInit, ViewChild} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {IonSearchbar, ModalController} from '@ionic/angular';
import {CustomerOfferService, Offer, VendorService} from '@cdq/api';
import {Platform} from '@ionic/angular';
import {AlertService} from 'src/app/services/alert/alert.service';
import {EnvService} from 'src/app/services/environment/env.service';

@Component({
    selector: 'app-customers-data',
    templateUrl: './customers-data.page.html',
    styleUrls: ['./customers-data.page.scss'],
})
export class CustomersDataPage implements OnInit {

    @Input('offer') offer: Offer
    @Input('image-src-base') imageBase: string | SafeResourceUrl

    customerFirstname: string
    customerLastname: string
    customerEmail: string
    customerPhone: string
    customerAvatar: string

    customerData: any = []

    imgClient: string[] | SafeResourceUrl[] = []

    searchItem: any

    // @ViewChild('search', { static: false}) search : IonSearchbar;

    isValid: boolean[] = [];
    isFinish: boolean = false;

    trackByCustomerData(index: number, item: any) {
        return item.account.id;
    }

    constructor(
        private modalController: ModalController,
        private vendorService: VendorService,
        private sanitizer: DomSanitizer,
        private http: HttpClient,
        private customerOffer: CustomerOfferService,
        public platform: Platform,
        private alertService: AlertService,
        private envService: EnvService,
        private ngZone: NgZone
    ) {
    }

    ngOnInit() {
        this.refresh()
    }

    refresh() {
        this.customerData = []
        this.vendorService.apiV1VendorOfferLikedCustomerOfferIdGet(this.offer.id).subscribe(r => {
            const dataCustomer = [];
            r.items.forEach(z => {
                this.downloadAvatar(z.customer.account.avatar, z.customer.account.id)
                // @ts-ignore
                dataCustomer.push({
                    account: z.customer.account,
                    passed: z.status == 'SCANNED',
                    trackingUuid: z.trackingUuid,
                    number: z.number
                })
            })
            this.ngZone.run(() => {
                this.isFinish = true;
                this.customerData = dataCustomer;
            })
        })
    }

    validateParticipation(data): void {
        this.vendorService.apiV1VendorOfferScanCustomerTrackingUuidTrackingUuidGet(data.trackingUuid).subscribe(async r3 => {
            console.log('uuidSubmit', data, r3);
            this.refresh();
            await this.alertService.success('Participation', 'Participation enregistrée')
        })
    }

    ionViewWillEnter() {
        this.refresh();
    }

    dismissModal(): void {
        this.modalController.dismiss().catch(e => {
            console.log(e)
        })
    }

    downloadAvatar(avatarHash: string | null, id: number): void {
        if (avatarHash != null) {
            let url = this.envService.apiUrl + '/api/v1/assets/download/' + avatarHash
            let headers: HttpHeaders = new HttpHeaders()
            this.http.get(url, {responseType: 'blob', headers: headers}).subscribe(response => {
                new Blob([response])
                // this.imgCards[id] = window.URL.createObjectURL(response)
                this.imgClient[id] = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(response));
            })
        } else
            this.imgClient[id] = './assets/logo/logo-vert.png'
    }

}
