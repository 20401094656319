import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {
  AssetsService,
  Offer,
  OfferForm,
  VendorService
} from "@cdq/api";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {catchError, map} from "rxjs/operators";
import {HttpClient, HttpErrorResponse, HttpEventType} from "@angular/common/http";
import {of} from "rxjs";
import {AlertService} from 'src/app/services/alert/alert.service';
import {DateManagerService} from 'src/app/services/date-manager/date-manager.service';
import {SharedService} from 'src/app/services/shared/shared.service';
import {UploadFileService} from 'src/app/services/upload/upload-file.service';

@Component({
  selector: 'app-modal-details-offer',
  templateUrl: './modal-details-offer.component.html',
  styleUrls: ['./modal-details-offer.component.scss'],
})
export class ModalDetailsOfferComponent implements OnInit {

  @Input('offer') offer : Offer
  @Input('type') type : 'current' | 'future' | 'ended' | 'draft'
  @Input('image-src-base') imageBase : string | SafeResourceUrl

  @ViewChild("inputPicture", {static: false}) fileUpload: ElementRef
  files : any[] = []

  uuid : string = ''
  offerForm : OfferForm = {
    address: {
      phoneNumber: ''
    },
    canHandlePrePayment: true,
    // description: string;
    // descriptionHtml: string;
    presentation: '',
    title: ''
  }

  constructor(
      public alertService : AlertService,
      public modalController : ModalController,
      private vendorService : VendorService,
      private uploadService : UploadFileService,
      private assetsService : AssetsService,
      private http : HttpClient,
      private sanitizer : DomSanitizer,
      private sharedService : SharedService,
      public dateManager : DateManagerService
  ) { }

  ngOnInit() {
    console.log(this.imageBase)

    if (this.type == 'future') {
      this.offerForm.address.zipCode = this.offer.address.zipCode
      this.offerForm.address.city = this.offer.address.city
      this.offerForm.address.country = this.offer.address.country
      this.offerForm.address.street = this.offer.address.street
      this.offerForm.address.street_2 = this.offer.address.street_2
      this.offerForm.address.fullAddress = this.offer.address.fullAddress
      this.offerForm.address.latitude = this.offer.address.latitude
      this.offerForm.address.longitude = this.offer.address.longitude

      this.offerForm.category = this.offer.category
      this.offerForm.title = this.offer.title
      this.offerForm.presentation = this.offer.presentation

      this.offerForm.descriptionHtml = this.offer.descriptionHtml
      this.offerForm.description = this.offer.description
      this.offerForm.offerNotificationType = this.offer.offerNotificationType
      this.offerForm.offerType = this.offer.offerType
      this.offerForm.startAt = this.offer.startAt
      this.offerForm.endAt = this.offer.endAt
      this.offerForm.maxAvailability = this.offer.maxAvailability
      this.offerForm.canHandlePrePayment = this.offer.canHandlePrePayment
    }

  }

  dismissModal() : void {
    this.modalController.dismiss().catch(e => {
      console.log(e)
    })
  }

  likeValidate() : void {
    this.alertService.load().then(() => {
      this.vendorService.apiV1VendorOfferScanCustomerTrackingUuidTrackingUuidGet(this.uuid).forEach(async r => {
        this.alertService.closeLoader()
        await this.alertService.success('Participation enregistrée', 'La participation a bien été prise en compte')

        this.dismissModal()
      }).catch(e => {
        console.log(e)
        this.alertService.closeLoader()
      })

    }).catch(e => {
      console.log(e)
    })
  }


  saveDetails() : void {
    this.alertService.load().then(() => {
      this.vendorService.updateOffer(this.offer.id, this.offerForm).forEach(async r => {
        console.log(r)
        this.sharedService.sendUpdateOfferEvent()
        this.alertService.closeLoader()
        this.dismissModal()
        await this.alertService.success('Modifications enregistrées', 'Vos modifications ont bien été prises en compte')
      }).catch(e => {
        this.alertService.closeLoader()
        console.log(e)
      })
    }).catch(e => {
      console.log(e)
    })

  }

  deleteOffer() : void {
    this.alertService.confirm(
        `Supprimer “${this.offer.title}”`,
        `Souhaitez-vous définitivement supprimer l'annonce ?`,
        'Supprimer',
        () => {
          this.alertService.loadWithMessage('Suppression en cours ...').then(() => {
            this.vendorService.deleteOffer(this.offer.id).forEach(async r => {
              this.sharedService.sendDeleteEvent()
              this.alertService.closeLoader()
              this.dismissModal()
              await this.alertService.success('Offre supprimée', 'Votre offre a été supprimée avec succès')
              console.log(r)
            }).catch(e => {
              this.alertService.closeLoader()
              console.log(e)
            })
          }).catch(e => {
            console.log(e)
          })
        }
    )

  }

  async statNavigation() : Promise<void> {
    await this.alertService.success('WIP', 'les statistiques ne sont pas encore disponibles')
  }

  changePresentation($event: any) : void {
    this.offerForm.presentation = $event.detail.value
  }
  changeTitle($event: any) : void {
    this.offerForm.title = $event.detail.value
  }

  uploadImage(): void {
    this.files.forEach(file => {
      this.uploadFile(file)
      this.sharedService.sendUpdateImageEvent()
    })
  }

  fileSelect(): void {
    const fileUpload = this.fileUpload.nativeElement;
    for (let index = 0; index < fileUpload.files.length; index++) {
      const file = fileUpload.files[index]
      this.files.push({data: file, inProgress: false, progress: 0})
    }
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (event) => {
        this.imageBase = event.target.result;
      }
    }
  }

  uploadFile(file : any) {

    this.uploadService.upload('image_offer', this.offer.id, 'offer', file.data).pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.progress = Math.round(event.loaded * 100 / event.total);
              break;
            case HttpEventType.Response:
              return event;
          }
        }),
        catchError((error: HttpErrorResponse) => {
          file.inProgress = false;
          return of(`${file.data.name} upload failed.`);
        })).subscribe((event: any) => {
      if (typeof (event) === 'object') {
      }
    });
  }
}
