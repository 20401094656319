import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {FormBuilder, FormGroup} from '@angular/forms';
import {COMMA, ENTER, SPACE} from '@angular/cdk/keycodes';
import {Keyboard} from '@capacitor/keyboard';
import {AlertService} from 'src/app/services/alert/alert.service';
import {UploadFileService} from 'src/app/services/upload/upload-file.service';
import {KeycloakIonicService} from 'src/app/services/keycloak/keycloak-ionic.service';
import {ClientContactForm, CrmService, SecurityService} from '@cdq/api';
import {AlertController} from '@ionic/angular';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-importer-contacts',
  templateUrl: './importer-contacts.component.html',
  styleUrls: ['./importer-contacts.component.scss']
})
export class ImporterContactsComponent implements OnInit {

  fileName: string = '';
  emailFile: File;
  clientContactList: string[] = [];
  public separatorKeysCodes = [ENTER, COMMA, SPACE];
  public emailList = [];
  removable = true;
  rulesForm: FormGroup;
  fb: FormBuilder = new FormBuilder();
  isEmailsValid: boolean = true;

  constructor(
    private modalCtrl: ModalController,
    private alertService: AlertService,
    private uploadService: UploadFileService,
    private kcService: KeycloakIonicService,
    private securityService: SecurityService,
    private crmService: CrmService,
    private alertController: AlertController,
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.rulesForm = this.fb.group({
      emails: this.fb.array([], []),
    });
  }

  dismissModal(object?: any): void {
    this.modalCtrl.dismiss(true);
  }

  uploadFile() {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'text/plain, .xls, .xlsx, .csv'; // Specify the file types to upload
    input.onchange = (event: any) => {
      this.emailFile = event.target.files[0];
      this.fileName = this.emailFile.name;
      console.log('Selected file:', this.emailFile);
    };
    input.click();
  }

  add(event): void {
    console.log(event.value);
    if (event.value) {
      if (this.validateEmail(event.value)) {
        this.emailList.push({value: event.value, invalid: false});
      } else {
        this.emailList.push({value: event.value, invalid: true});
      }
      this.checkEmailValidity();
    }
    if (event.input) {
      event.input.value = '';
    }
  }

  send(): void {
    this.alertService.loadWithMessage("Sauvegarde ...").then(() => {
      this.kcService.isLoggedIn().then(connected => {
        if (connected) {
          this.securityService.apiV1SecurityLoadGet().subscribe(security => {
            if (security.result) {
              if (this.emailFile != null) {
                this.alertService.loadWithMessage("Sauvegarde ...").then(() => {
                  this.applyFile().then(applied => {
                    if (applied) {
                      this.alertService.temp('Les contacts ont bien été enregistrées. Un e-mail de télecharger de OuiLoveLocal Appli leur à été envoyé.', 4000, 'alert-basic');
                      this.dismissModal(true);
                    }
                  });
                });
              }
              if (this.emailList.length > 0) {
                this.alertService.loadWithMessage("Sauvegarde ...").then(() => {
                  this.applyList().then(applied => {
                    if (applied) {
                      this.alertService.temp('Les contacts ont bien été enregistrées. Un e-mail de télecharger de OuiLoveLocal Appli leur à été envoyé.', 4000, 'alert-basic');
                      this.dismissModal(true);
                    }
                  });
                });
              }
            }
          });
        }
      });
    })
  }

  applyList(): Promise<any> {
    this.clientContactList = [];
    this.emailList.forEach(r => {
      this.clientContactList.push(r.value);
    });

    return new Promise((resolve, reject) => {
      const clientContactForm: ClientContactForm = {
        emailList: this.clientContactList
      };

      this.crmService.addClientContactManually(clientContactForm).subscribe(res => {
        console.log('[ma liste] apply', res);

        if (res.errors && res.errors.fieldErrors && res.errors.fieldErrors.length > 0) {
          this.alertService.infos(
            `${res.errors.fieldErrors.length} e-mail(s) déjà enregistré(s) par vous.`,
            'Veuillez vérifier les courriels et réessayer.',
            'Ok',
            () => { },
            'alert-danger'
          );
        }

        const emailAddedCount = res.result && res.result.emailAddedCount ? res.result.emailAddedCount : 0;
        if (emailAddedCount > 0) {
          this.alertService.infos(
            `${emailAddedCount} e-mail(s) ajouté(s) avec succès.`,
            'Vous pouvez maintenant les voir dans votre liste de contacts.',
            'Ok',
            () => { },
            'alert-success'
          );
        } else {
          this.alertService.infos(
            'Aucun e-mail ajouté.',
            "Aucun nouveau courriel n'a été ajouté à votre liste de contacts.",
            'Ok',
            () => { },
            'alert-info'
          );
        }

        resolve(true);
      }, error => {
        this.alertService.infos(
          'Une erreur est survenue',
          `Impossible d'ajouter les adresses e-mails. [${error.error}]`,
          'Ok',
          () => { },
          'alert-danger'
        );
        reject(false);
      });
    });
  }


  public blobToFile(theBlob: Blob, fileName: string): File {
    const b: any = theBlob;
    // A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    // Cast to a File() type
    return theBlob as File;
  }

  applyFile(): Promise<any> {
    return new Promise((resolve, reject) => {
      let fileType = this.emailFile && this.emailFile.type ? this.emailFile.type : 'text/csv';
      this.alertService.loadWithMessage("Sauvegarde ...").then(() => {
        this.uploadService.uploadClientContact(this.fileName, this.emailFile, fileType).subscribe(
          async (res: any) => {
            console.log('[ma liste] uploadClientContact', res);
            if (res.status) {
              let result = res.result;
              let message = `
              <ul>
                <li>Contacts enregistrés : ${result.savedContactCount}</li>
                <li>Invitations envoyées : ${result.invitationSentCount}</li>
                <li>Contacts mis à jour : ${result.updatedContactsCount}</li>
                <li>Contacts en erreur : ${result.errrorContactsCount}</li>
              </ul>`;


              // Create and present the alert
              const alert = await this.alertController.create({
                header: 'Importation de résultats',
                message: message,
                buttons: [{
                  text: 'OK',
                  handler: () => {
                    this.modalCtrl.dismiss(true);
                    resolve(true);
                  }
                }],
              });
              this.modalCtrl.dismiss();
              await alert.present();
            } else {
              let errorMessage = 'Une erreur est survenue, impossible d\'ajouter les adresses e-mails.';
              if (res.errors && res.errors.fieldErrors.length > 0) {
                errorMessage += ` ${res.errors.fieldErrors.length} adresse-mail a déjà été enregistré par vous.`;
              }

              const alert = await this.alertController.create({
                header: 'Error',
                message: errorMessage,
                buttons: ['OK'],
                cssClass: 'my-custom-alert',
              });
              this.modalCtrl.dismiss();
              await alert.present();
              reject(false);
            }
          })
      },
        async error => {
          console.error('Error uploading file:', error);
          const alert = await this.alertController.create({
            header: 'Error',
            message: `Une erreur est survenue, impossible d'ajouter les adresses e-mails. [${error.error}]`,
            buttons: ['OK'],
            cssClass: 'my-custom-alert',
          });

          await alert.present();
          reject(false);
        }
      );
    });
  }




  hideKeyboard(event: any): void {
    console.log(event);
    Keyboard.hide();
  }

  removeEmail(data: any): void {
    if (this.emailList.indexOf(data) >= 0) {
      this.emailList.splice(this.emailList.indexOf(data), 1);
    }
    this.checkEmailValidity();
  }

  checkEmailValidity(): void {
    this.isEmailsValid = this.emailList.every(email => !email.invalid);
  }

  private validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  downloadFile() {
    const fileUrl = 'assets/Format Fichier.xlsx';
    this.http.get(fileUrl, {responseType: 'blob'}).subscribe((response: Blob) => {
      const blob = new Blob([response], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Format Fichier.xlsx';
      a.click();
      window.URL.revokeObjectURL(url);
    }, (error) => {
      console.error('Error fetching the file', error);
    });
  }
}
