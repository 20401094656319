import { Component, Input, OnInit, Output } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import * as moment from "moment";
import { OpenPeriod, VendorProfilService } from "@cdq/api";
import {AlertService} from "src/app/services/alert/alert.service";


@Component({
  selector: "app-multi-open-period",
  templateUrl: "./multi-open-period.page.html",
  styleUrls: ["./multi-open-period.page.scss"],
})
export class MultiOpenPeriodPage implements OnInit {
  @Input("day") day: number = 0;
  @Input("open") open: OpenPeriod[] = [];


  // newPeriod: OpenPeriod = {
  //   day: 0,
  //   first: "00:00 - 00:00",
  //   second: "Fermé",
  // };

  openPeriods: {
    firstStart: string;
    firstEnd: string;
    secondStart: string;
    secondEnd: string;
    allDay: boolean;
    closeMorning: boolean;
    closeAllDay: boolean;
    closeAfter: boolean;
  } = {
    firstStart: "00:00",
    firstEnd: "00:00",
    secondStart: "00:00",
    secondEnd: "00:00",
    allDay: false,
    closeMorning: false,
    closeAllDay: false,
    closeAfter: false,
  };
  openSecond: boolean = false;

  bool: boolean = false;
  secondAdded: boolean = false;

  days = [
    { n: 0, val: "L", isChecked: false },
    { n: 1, val: "M", isChecked: false },
    { n: 2, val: "M", isChecked: false },
    { n: 3, val: "J", isChecked: false },
    { n: 4, val: "V", isChecked: false },
    { n: 5, val: "S", isChecked: false },
    { n: 6, val: "D", isChecked: false },
  ];

  allSelected : boolean = false
  selectedItem = [];

  isClicked0 : boolean = false
  isClicked1 : boolean = false
  isClicked2 : boolean = false
  isClicked3 : boolean = false
  isClicked4 : boolean = false
  isClicked5 : boolean = false
  isClicked6 : boolean = false

  error : string = ''

  dateFirstStart: any;
  dateFirstEnd: any;
  dateSecondStart: any;
  dateSecondEnd: any;

  isWeb: boolean = false;


  constructor(
    private modalController: ModalController,
    private alertService: AlertService,
    private vendorProfileService: VendorProfilService,
    public platform: Platform,
  ) {}

  ngOnInit() {
    if(this.platform.platforms().includes('desktop')) {
      this.isWeb = true;
    }

    let dateFormat = "YYYY-MM-DDTHH:mm:ss.sssZ";

    this.dateFirstStart = moment().hours(0).minutes(0).seconds(0).milliseconds(0).format(dateFormat);
    this.dateFirstEnd = moment().hours(1).minutes(0).seconds(0).milliseconds(0).format(dateFormat);
    this.dateSecondStart = moment().hours(0).minutes(0).seconds(0).milliseconds(0).format(dateFormat);
    this.dateSecondEnd = moment().hours(1).minutes(0).seconds(0).milliseconds(0).format(dateFormat);

    // this.dateFirstStart.setHours(0, 0, 0, 0)
    // this.dateFirstEnd.setHours(1, 0, 0, 0)
    // this.dateSecondStart.setHours(0, 0, 0, 0)
    // this.dateSecondEnd.setHours(1, 0, 0, 0)
  }

  async dismissModal(): Promise<void> {
    await this.modalController.dismiss();
  }

  next() {
    this.bool = !this.bool;
    for (let day of this.days) {
      if (day.isChecked == true) {
        console.log(day.val);
      }
    }
  }

  showDate() {
    console.log(this.dateFirstEnd);
    console.log(this.dateFirstStart);
    this.openPeriods.firstStart = this.dateFirstStart.toISOString().toString().substring(11, 15);
    this.openPeriods.firstEnd = this.dateFirstEnd.toISOString().toString().substring(11, 15);
  }

  toggle(value: string): void {
    switch (value) {
      case "open-second":
        this.openSecond = !this.openSecond;
        break;
      case "open-all":
        this.openPeriods.allDay = !this.openPeriods.allDay;
        break;
      case "close-all":
        this.openPeriods.closeAllDay = !this.openPeriods.closeAllDay;
        break;
      case "close-morning":
        this.openPeriods.closeMorning = !this.openPeriods.closeMorning;
        break;
      case "close-after":
        this.openPeriods.closeAfter = !this.openPeriods.closeAfter;
        break;
      default:
        console.log("Value invalid");
        break;
    }
  }

  onChange(item) {
    this.days[item].isChecked = !this.days[item].isChecked
    if (this.selectedItem.includes(item)) {
      this.selectedItem = this.selectedItem.filter((value) => value != item);
      this.days[item].isChecked == !this.days[item].isChecked
    } else {
      this.days[item].isChecked == !this.days[item].isChecked
      this.selectedItem.push(item);
    }
    console.log(this.selectedItem)
  }

  selectAllDay() {
    console.log(this.allSelected)
    this.selectedItem = []
    if(this.allSelected == false) {
      this.days.forEach(r => {
        r.isChecked = true
        this.selectedItem.push(r.n)
      })
      this.bool = true
    } else {
      this.days.forEach(r => {
        r.isChecked = false
      });
      this.bool = false
    }
    console.log(this.selectedItem);
  }

  checkSelectedDay() {

    this.openPeriods.firstStart = this.dateFirstStart.substring(11, 16)
    this.openPeriods.firstEnd = this.dateFirstEnd.substring(11, 16)

    if(this.secondAdded == true) {
      this.openPeriods.secondStart = this.dateSecondStart.substring(11, 16);
      this.openPeriods.secondEnd = this.dateSecondEnd.substring(11, 16);
    }

    this.selectedItem.sort()
    this.selectedItem.forEach(r => {
      console.log("SaveOpenPeriod day : ", r)
      this.saveOpenPeriod(r)
    })

    if(this.selectedItem.length > 0){
      this.vendorProfileService.saveOpenPeriods(this.open).forEach(r => {
        console.log(r)
        this.modalController.dismiss()
      })
    } else {
      this.error = "Veuillez selectionner au moins un jour"
    }
  }

  saveOpenPeriod(day: number) {

    let newPeriod = {
      day: 0,
      first: "00:00 - 00:00",
      second: "Fermé",
    };

    newPeriod.day = day
    console.log(this.openPeriods.firstStart, this.openPeriods.firstEnd);
    newPeriod.first = this.openPeriods.firstStart + ' - ' + this.openPeriods.firstEnd;

    if(this.secondAdded == true) {
      newPeriod.second = this.openPeriods.secondStart + ' - ' + this.openPeriods.secondEnd;
    }
    // newPeriod.second = `${this.openPeriods.firstEnd}`

    if(this.openPeriods.allDay) {
      newPeriod.first = '24h'
      newPeriod.second = '24';
    }
    if (this.openPeriods.closeAllDay) {
      newPeriod.first = 'Fermé'
      newPeriod.second = null
    }
    console.log('new', newPeriod)
    this.open[day] = newPeriod
  }
}

