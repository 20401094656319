import { SafeResourceUrl } from '@angular/platform-browser';
import { Component, Input, OnInit } from '@angular/core';
import {ModalController} from "@ionic/angular";
import {AssetsService} from "@cdq/api";
import {AlertService} from 'src/app/services/alert/alert.service';

@Component({
  selector: 'app-modal-image',
  templateUrl: './modal-image.component.html',
  styleUrls: ['./modal-image.component.scss'],
})
export class ModalImageComponent implements OnInit {

  @Input('image') image : SafeResourceUrl | string = './assets/logo/logo-vert.png'
  @Input('hash') hash : string
  @Input('modal') modal: boolean = false

  constructor(
      public alertService : AlertService,
      private modalController : ModalController,
      private assetsService : AssetsService
  ) { }

  ngOnInit() {}

  async dismissModal(action?: string) : Promise<void> {
    await this.modalController.dismiss(action)
  }

  async deleteImage() {
    await this.alertService.confirm(
        'Supprimer l\'image', 'Souhaitez-vous supprimer définitivement l\'image', 'Supprimer',
        () => {

          this.assetsService.removeAsset(this.hash).forEach(r => {
            console.log('delete', this.hash)
            this.dismissModal('delete')
          }).catch(e => {
            console.log(e)
          })
        }
    )
  }
}
